import styled from "styled-components";
import { Box } from "@gympass/yoga";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: 0 auto;
`;

export const Wrapper = styled(Box)`
  max-width: 700px;
`;
