"use client";

import { Dropdown } from "@gympass/yoga";
import { StaticSessionTitlesProps } from "@/app/_widgets/body-static-list-by-session/StaticSessionTitles";

export const MobileStaticSessionTitles: React.FC<StaticSessionTitlesProps> = (
  props
) => {
  return (
    <Dropdown
      full
      data-cy="mobile-session-titles"
      className="mobile-session-titles"
      options={props.sessions.map((session) => ({
        label: session.title,
        value: session.title,
        selected: props.activeSession.title === session.title,
      }))}
      onChange={(e: DropdownOnChange) =>
        props.updateActiveSession(
          props.sessions.filter((session) => session.title === e.value)[0]
        )
      }
      {...props}
    />
  );
};
