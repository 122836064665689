import { Box, Button, theme } from "@gympass/yoga";
import styled from "styled-components";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";

export const Container = styled.div`
  max-width: ${DESKTOP_MAX_WIDTH_REM};

  .contact-reason-title {
    margin-bottom: ${theme.spacing.xlarge}px;
  }

  .category-title {
    margin-bottom: ${theme.spacing.xxsmall}px;
  }

  .subcategory-text {
    height: fit-content;
    text-align: start;
    padding: 0;
    margin-bottom: ${theme.spacing.xsmall}px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin: ${theme.spacing.large}px 0 0;
  }
`;

export const CategoryList = styled(Box)`
  columns: 2 auto;
  column-gap: ${theme.spacing.large}px;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    columns: 1;
  }
`;

export const CategoryContent = styled.ul`
  margin-top: 0;
  margin-bottom: ${theme.spacing.xxlarge}px;
  break-inside: avoid;
  padding: 0;
`;

export const SubcategoryContent = styled.li`
  margin-left: ${theme.spacing.large}px;
`;

export const ButtonLink = styled(Button.Link)`
  display: flex;
  justify-content: flex-start;
`;
