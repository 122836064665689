import { DesktopPagination } from "./desktop";
import { MobilePagination } from "./mobile";
import * as Styled from "./styles";

export type PaginationProps = {
  page: number;
  totalPages: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <Styled.Container>
      <DesktopPagination {...props} />
      <MobilePagination {...props} />
    </Styled.Container>
  );
};
