"use client";

import * as Styled from "./styles";
import { Icon, Text } from "@gympass/yoga";
import { ChevronRight } from "@gympass/yoga-icons";
import { useDispatchEvent } from "@/app/lib/segment";
import useDevice from "@/app/_hooks/useDevice";

const BodyDynamicBreadcrumb: React.FC<
  BodyDynamicBreadcrumbWidget["payload"]
> = (props) => {
  const isMobile = useDevice();
  const { dispatchAnalycts } = useDispatchEvent();

  return (
    <Styled.Container data-cy="breadcrumb-widget">
      <Styled.Breadcrumb
        data-cy={isMobile ? "mobile-breadcrumb" : "desktop-breadcrumb"}
        maxItems={isMobile ? 2 : 10}
        separator={
          <Icon as={ChevronRight} width="small" height="medium" fill="deep" />
        }
        aria-label="breadcrumb"
      >
        {props.items.map((item, index) =>
          item.type === "link" ? (
            <Styled.BreadcrumbLink
              prefetch={false}
              key={index}
              title={item.name}
              className="breadcrumb-button"
              data-cy="breadcrumb-with-link"
              href={`${item.url}`}
              onClick={() => {
                dispatchAnalycts({
                  type: "track",
                  event: "hc_click_breadcrumb",
                  properties: {
                    breadcrumb_title: item.name,
                  },
                });
              }}
            >
              <Text.Body1 bold color="primary" fontSize="small">
                {item.name}
              </Text.Body1>
            </Styled.BreadcrumbLink>
          ) : (
            <Text.Body2 key={index} color="deep" data-cy="breadcrumb-with-text">
              {item.name}
            </Text.Body2>
          )
        )}
      </Styled.Breadcrumb>
    </Styled.Container>
  );
};

export default BodyDynamicBreadcrumb;
