import * as Styled from "./styles";
import { setFormTicketCookie } from "@/app/_server-actions/cookies/formTicket";
import { Button } from "@gympass/yoga";
import { AlertTriangle, CheckedFull } from "@gympass/yoga-icons";
import { FormField } from "@/app/_components/FormField";
import { FormEvent, useCallback, useState, useRef, useContext } from "react";
import { useParams, useRouter } from "next/navigation";
import useNotification from "@/utils/useNotification";
import { DEFAULT_SNACKBAR_DURATION_IN_MS } from "@/app/_providers/NotificationProvider";

type FieldsValue = { [key: string]: string };

export const Form: React.FC<{
  fields: FormFieldType[];
  formId: string;
  buttons: {
    change: string;
    submit: string;
  };
  updateFormFieldValue: (field: FormFieldType, value: string) => void;
  submitFeedbackMessages: {
    success: string;
    error: string;
  };
}> = (props) => {
  const { fields, formId, submitFeedbackMessages } = props;
  const { region } = useParams<{ region: Region }>();
  const [errors, setErrors] = useState<FieldsValue>();
  const [isLoading, setIsLoading] = useState(false);
  const { notificate } = useNotification();
  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setIsLoading(true);

      const formDataForFetch = new FormData();
      let subjectValue;

      for (const field of fields) {
        if (field.showField) {
          const { id, value, name } = field;
          if (field.kind === "subject") subjectValue = value;
          const dataToAppend = {
            id,
            value,
            name,
            errors: field?.validity_rules || [],
          };

          formDataForFetch.append(field.name, JSON.stringify(dataToAppend));
        }
      }

      formDataForFetch.append("formId", JSON.stringify({ formId: formId }));

      try {
        const response = await fetch("/api/form", {
          method: "POST",
          body: formDataForFetch,
        });

        const data = await response.json();

        if (data.success) {
          const { data: response } = data;
          setFormTicketCookie(response.id, subjectValue);

          // TODO: consume via back-end
          setIsLoading(false);
          notificate({
            key: "ticket-form-success",
            icon: CheckedFull,
            type: "success",
            message: submitFeedbackMessages.success,
            duration: DEFAULT_SNACKBAR_DURATION_IN_MS,
            onClose: () => {
              router.push(`/${region}`);
            },
          });
        } else {
          setIsLoading(false);
          setErrors(data.error);

          notificate({
            key: "ticket-form-error",
            icon: AlertTriangle,
            type: "failure",
            message: submitFeedbackMessages.error,
            duration: DEFAULT_SNACKBAR_DURATION_IN_MS,
          });

          if (Object.keys(data?.error).length > 0) {
            formRef?.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      } catch (err) {
        //TODO: send error event to tracker
        console.log(err);
      }
    },
    [
      formId,
      fields,
      notificate,
      submitFeedbackMessages.success,
      submitFeedbackMessages.error,
      router,
      region,
    ]
  );

  const clearFieldError = (field: FormFieldType) => {
    let updatedErrors = { ...errors };
    if (updatedErrors) {
      updatedErrors[field.name] = "";
    }

    setErrors((prev) => ({
      ...prev,
      ...updatedErrors,
    }));
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      {props.fields.map((field) => {
        return (
          field.showField && (
            <Styled.BoxField
              key={field.id}
              $isVisible={!!field.shouldHideForUser}
            >
              <FormField
                field={field}
                onChange={props.updateFormFieldValue}
                error={errors?.[field.name]}
                formId={formId}
                clearError={clearFieldError}
              />
            </Styled.BoxField>
          )
        );
      })}
      <Styled.BoxButton>
        <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>
          {props.buttons.submit}
        </Button>
      </Styled.BoxButton>
    </form>
  );
};
