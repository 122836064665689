/* eslint-disable import/no-anonymous-default-export */
import AppStore from "@/app/_widgets/svg/app-store";
import GympassLogo from "@/app/_widgets/svg/gympass-logo";
import GooglePlay from "@/app/_widgets/svg/google-play";
import HelpCenterLogo from "@/app/_widgets/svg/helpcenter-logo";
import WellhubBlackLogo from "@/app/_widgets/svg/wellhub-black-logo";
import WellhubWhiteLogo from "@/app/_widgets/svg/wellhub-white-logo";

export default {
  GympassLogo,
  AppStore,
  GooglePlay,
  HelpCenterLogo,
  WellhubBlackLogo,
  WellhubWhiteLogo,
};
