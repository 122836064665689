import { theme } from "@gympass/yoga";
import styled from "styled-components";

export const Container = styled.div`
  height: 24px;

  #ot-sdk-btn.ot-sdk-show-settings,
  #ot-sdk-btn.optanon-show-settings {
    color: ${theme.colors.white} !important;
    font-size: 16px;
    line-height: 24px;
    font-family: Inter;
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0 ${theme.spacing.xxxsmall}px !important;
`;
