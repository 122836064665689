import styled from "styled-components";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import { Box, Text, theme } from "@gympass/yoga";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: ${theme.spacing.xxxsmall}px auto;

  .title {
    margin-bottom: ${theme.spacing.xlarge}px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    .title {
      margin: 1rem 0 2rem;
    }
  }
`;

export const Title = styled(Text.Display1)`
  max-width: ${DESKTOP_MAX_WIDTH_REM};

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.xlarge}px;
    line-height: ${theme.lineHeights.xlarge}px;
  }
`;

export const DropdownContent = styled(Box)`
  max-width: 44rem;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-bottom: 0;
  }
`;

export const PersonaSubtitle = styled(Text.Body1)`
  margin-bottom: ${theme.spacing.xsmall}px;
`;