"use client";
import { useCallback, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { Form } from "./Form";
import ContactReason from "./ContactReason";
import {
  NOTIFICATION_SUBSCRIBERS,
  NotificationProvider,
} from "@/app/_providers/NotificationProvider";
import * as Styled from "./styles";
import { ProfileBanner } from "./ProfileBanner";
import { WorkingHoursBanner } from "./WorkingHoursbanner";

const CONTACT_REASON_QUERY_PARAMS_NAME = "contact-reason";

const BodyDynamicTicketForm: React.FC<
  BodyDynamicTicketFormWidget["payload"]
> = (props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [formFields, setFormFields] = useState<FormFieldType[]>(props.fields);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const getFormWithContactReasonUrl = (
    title: string,
    value: string,
    fieldId: string
  ) => {
    return `${pathname}?${createQueryString(
      CONTACT_REASON_QUERY_PARAMS_NAME,
      `${title}::${value}::${fieldId}`
    )}`;
  };

  const updateFormFieldValue = useCallback(
    (field: FormFieldType, value: string) => {
      const updatedField = formFields?.map((visibleField) =>
        visibleField.id === field.id ? { ...field, value } : visibleField
      );
      setFormFields(updatedField);
    },
    [formFields]
  );

  const shouldShowField = (field: ApiFormField): boolean => {
    const rules = field.visibility_rules;

    if (rules && rules.length) {
      return formFields.some((formField) =>
        rules.find(
          (rule) =>
            rule.form_field_id === formField.id &&
            formField.value === rule.value
        )
      );
    }

    return true;
  };

  const fieldsVisibility: FormFieldType[] = formFields?.map((field) => {
    return {
      ...field,
      showField: shouldShowField(field),
      shouldHideForUser: !props.disallowedFields.includes(field.id),
    };
  });

  const getContactReasonUrl = () => {
    const params = new URLSearchParams(searchParams.toString());
    params.delete(CONTACT_REASON_QUERY_PARAMS_NAME);

    return `${pathname}?${params.toString()}`;
  };

  return (
    <NotificationProvider subscribers={NOTIFICATION_SUBSCRIBERS}>
      <Styled.Container>
        {props.showContactReason && (
          <ContactReason
            contactReasons={props.contact_reasons}
            title={props.contactReasonTitle ?? ""}
            getFormWithContactReasonUrl={getFormWithContactReasonUrl}
          />
        )}
        {props.showForm && (
          <Styled.Wrapper>
            <Styled.PersonaTitle className="persona-title">
              {props.submitTitle}
            </Styled.PersonaTitle>

            <ProfileBanner
              {...props}
              getContactReasonUrl={getContactReasonUrl}
            />

            <Styled.SubmitSubtitle bold>
              {props.submitSubtitle}
            </Styled.SubmitSubtitle>

            <WorkingHoursBanner {...props} />

            <Form
              buttons={props.buttons}
              fields={fieldsVisibility}
              updateFormFieldValue={updateFormFieldValue}
              formId={props.id}
              submitFeedbackMessages={props.submitFeedback}
            />
          </Styled.Wrapper>
        )}
      </Styled.Container>
    </NotificationProvider>
  );
};

export default BodyDynamicTicketForm;
