"use client";
import * as Styled from "./styles";
import { Button, Text } from "@gympass/yoga";

const ContactReason: React.FC<{
  contactReasons: ContactReason;
  title: string;
  getFormWithContactReasonUrl: (
    title: string,
    value: string,
    fieldId: string
  ) => void;
}> = (props) => {
  return (
    <Styled.Container className="contact-reason-container">
      {props.title && (
        <Text.H4 bold className="contact-reason-title">
          {props.title}
        </Text.H4>
      )}

      <Styled.CategoryList>
        {props.contactReasons.options.map((contactReason, index) => (
          <Styled.CategoryContent key={index}>
            <Text.H5 bold className="category-title">
              {contactReason.category}
            </Text.H5>
            {contactReason.subcategories.map((subCategory) => (
              <Styled.SubcategoryContent key={subCategory.id}>
                <Styled.ButtonLink
                  href={props.getFormWithContactReasonUrl(
                    subCategory.title,
                    subCategory.value,
                    props.contactReasons.form_field_id
                  )}
                  className="subcategory-text"
                >
                  {subCategory.title}
                </Styled.ButtonLink>
              </Styled.SubcategoryContent>
            ))}
          </Styled.CategoryContent>
        ))}
      </Styled.CategoryList>
    </Styled.Container>
  );
};

export default ContactReason;
