import { Input } from "@gympass/yoga";
import { useState } from "react";

export const InputEmail: React.FC<{
  full: boolean;
  error?: string;
  label: string;
  name: string;
  value: string;
  onBlur: (value: string) => void;
  onClean: (cleaned: string) => void;
}> = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <Input.Email
      {...props}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      onBlur={() => props.onBlur(value)}
      error={props.error}
      onClean={() => setValue("")}
    />
  );
};
