"use client";

import * as Styled from "./styles";
import { useParams } from "next/navigation";
import { useEffect, useState } from "react";

const COOKIE_LANGUAGE: { [key: string]: string } = {
  "es-ar": "es",
  "pt-br": "pt-br",
  "es-cl": "es",
  "de-de": "de",
  "es-es": "es",
  "en-ie": "en",
  "it-it": "it",
  "es-mx": "es",
  "en-gb": "en",
  "en-us": "en",
};

const CookiesSettingsLink = () => {
  const { region } = useParams<{ region: Locale }>();
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  useEffect(() => {
    if (window.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml();
      window.OneTrust.changeLanguage(COOKIE_LANGUAGE[region]);
      setShowCookieSettings(true);
    }
  }, [region]);

  return (
    <Styled.Container>
      {showCookieSettings ? (
        <Styled.Button
          id="ot-sdk-btn"
          title="Cookie"
          className="ot-sdk-show-settings"
        >
          Cookie Settings
        </Styled.Button>
      ) : (
        <></>
      )}
    </Styled.Container>
  );
};

export default CookiesSettingsLink;
