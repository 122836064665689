import styled from "styled-components";
import { Box, theme } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";

export const SessionContent = styled(Box)`
  margin-top: ${theme.spacing.xxlarge}px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${theme.spacing.xlarge}px;
  row-gap: ${theme.spacing.large}px;
  align-items: stretch;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
