import { DesktopDynamicSessionContent } from "./desktop";
import { MobileDynamicSessionContent } from "./mobile";
import * as Styled from "./styles";

export type DynamicSessionContentProps = {
  activeSession: AllArticlesPageBuildScreenActionResultData;
};

export const DynamicSessionContent: React.FC<DynamicSessionContentProps> = (
  props
) => {
  return (
    <Styled.Container data-cy="dynamic-list-session-content">
      <DesktopDynamicSessionContent {...props} />
      <MobileDynamicSessionContent {...props} />
    </Styled.Container>
  );
};
