import * as Styled from "../styles";
import { PaginationProps } from "..";
import PaginationItem from "@mui/material/PaginationItem";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

export const DesktopPagination: React.FC<PaginationProps> = (props) => {
  return (
    <Styled.Pagination
      className="desktop-pagination"
      count={props.totalPages}
      siblingCount={2}
      boundaryCount={2}
      page={props.page}
      onChange={props.onChange}
      size="large"
      showFirstButton
      showLastButton
      renderItem={(item) =>
        item.selected ? (
          <Styled.SelectedPagination
            slots={{ first: SkipPreviousIcon, last: SkipNextIcon }}
            {...item}
          />
        ) : (
          <PaginationItem
            slots={{ first: SkipPreviousIcon, last: SkipNextIcon }}
            {...item}
          />
        )
      }
    />
  );
};
