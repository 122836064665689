import { DesktopDynamicSessionTitles } from "./desktop";
import { MobileDynamicSessionTitles } from "./mobile";
import * as Styled from "./styles";

export type DynamicSessionTitlesProps = {
  sessions: BodyDynamicShowPromotedArticlesWidget["payload"]["sessions"];
  activeSession: SessionWithPromotedArticles;
  updateActiveSession: (activeSession: SessionWithPromotedArticles) => void;
};

export const DynamicSessionTitles: React.FC<DynamicSessionTitlesProps> = (
  props
) => {
  return (
    <Styled.Container data-cy="dynamic-session-titles">
      <DesktopDynamicSessionTitles {...props} />
      <MobileDynamicSessionTitles {...props} />
    </Styled.Container>
  );
};
