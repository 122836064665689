import styled from "styled-components";
import { theme } from "@gympass/yoga";

type InputProps = {
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

const getBorderColor = (props: InputProps) => {
  if (props.isDragAccept) return theme.colors.hope;
  if (props.isDragReject) return theme.colors.upliftNew;
  if (props.isFocused) return theme.colors.staminaNew;
  return theme.colors.lightNew;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${theme.spacing.xlarge}px;
  border: 1px dashed
    ${(props) => getBorderColor(props as unknown as InputProps)};
  border-radius: 8px;
  color: ${theme.colors.deepNew};
  cursor: pointer;

  > p {
    margin-top: ${theme.spacing.xsmall}px;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.xsmall}px;
  gap: ${theme.spacing.xxsmall}px;
`;
