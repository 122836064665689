"use client";
import * as Styled from "./styles";

const BodyDynamicPageTitle: React.FC<BodyDynamicPageTitleWidget["payload"]> = (
  props
) => {
  return (
    <Styled.Container data-cy="dynamic-page-title">
      <Styled.Title data-cy="title">{props.title}</Styled.Title>
      {props.subtitle && (
        <Styled.Subtitle data-cy="subtitle" color="deep">
          {props.subtitle}
        </Styled.Subtitle>
      )}
    </Styled.Container>
  );
};

export default BodyDynamicPageTitle;
