const GympassLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="145"
      height="28"
      viewBox="0 0 145 28"
      fill="none"
    >
      <g clipPath="url(#clip0_296_4883)">
        <path
          d="M17.1058 7.59409L20.9963 5.42421C19.6747 2.02044 16.335 0 11.8936 0C4.55137 0 0 4.63865 0 11.3734C0 18.7434 6.01962 22.8216 11.746 22.8216C16.9948 22.8216 20.4455 20.3155 21.9137 18.2203V10.288H10.6086V13.9543H17.6932L17.6566 16.4604C17.6566 16.4604 15.5642 18.4808 11.8203 18.4808C7.78209 18.4808 5.46975 15.6748 5.46975 11.2604C5.46975 6.84589 7.78209 4.00253 11.7826 4.00253C14.1316 4.00253 15.8941 5.46157 17.1048 7.59409H17.1058Z"
          fill="white"
        />
        <path
          d="M23.8855 27.7936V24.0506C24.7239 24.1981 25.2737 24.2276 25.8516 24.2276C27.5861 24.2276 28.8874 23.6091 29.4662 22.3713L29.582 21.9289L22.7 7.22345H28.1649C28.1649 7.22345 31.9821 17.2725 32.0979 17.8919H32.1847C32.2715 17.361 35.9441 7.22345 35.9441 7.22345H41.0617L34.527 22.6368C32.9073 26.4682 30.5081 28 26.3436 28C25.7078 28 24.6949 27.941 23.8855 27.7936Z"
          fill="white"
        />
        <path
          d="M47.4228 7.22344V10.17C48.4058 7.90085 50.1991 6.78101 52.772 6.78101C55.3448 6.78101 57.0223 7.90085 57.8896 10.1995C59.0752 7.93034 61.0123 6.78101 63.5571 6.78101C67.1718 6.78101 69.1957 9.07968 69.1957 13.2346V21.9574H64.2507V14.148C64.2507 11.7313 63.3256 10.5525 61.4744 10.5525C59.6231 10.5525 58.3806 11.9083 58.3806 14.2365V21.9574H53.4357V14.148C53.4357 11.7313 52.5105 10.5525 50.6593 10.5525C48.8081 10.5525 47.5656 11.9378 47.5656 14.325V21.9574H42.6206V7.22344H47.4209H47.4228Z"
          fill="white"
        />
        <path
          d="M71.9722 7.22343H76.7724V9.69908C77.958 7.78384 80.1266 6.78198 82.6715 6.78198C87.0087 6.78198 89.8718 9.84656 89.8718 14.5324C89.8718 19.2182 86.8649 22.4008 82.4689 22.4008C80.0398 22.4008 78.1317 21.4579 76.9171 19.8956V28H71.9722V7.22343ZM84.7532 14.5324C84.7532 11.7618 83.3072 10.171 80.8202 10.171C78.3333 10.171 76.8004 11.6743 76.8004 14.2974V14.9158C76.8004 17.3915 78.3912 19.0127 80.7913 19.0127C83.1914 19.0127 84.7532 17.303 84.7532 14.5334V14.5324Z"
          fill="white"
        />
        <path
          d="M91.7788 17.9204C91.7788 16.5646 92.3277 15.4448 93.3406 14.7084C94.3526 14.0005 95.7986 13.5591 98.3724 13.2936L103.751 12.7922V12.3497C103.751 10.699 102.652 9.90359 100.686 9.90359C98.7197 9.90359 97.5052 10.699 97.3026 12.1728H92.2419C92.6181 8.81325 95.8276 6.78003 100.715 6.78003C106.209 6.78003 108.724 8.81325 108.724 13.0861V17.654C108.724 19.5397 108.84 20.925 109.072 21.9564H104.185C104.069 21.2485 103.982 20.3351 103.982 19.4513C103.028 21.3075 100.773 22.3979 97.7078 22.3979C94.0641 22.3979 91.7798 20.6881 91.7798 17.9185L91.7788 17.9204ZM103.865 16.1222V15.1793L99.8744 15.5913C97.6769 15.8568 96.8386 16.4457 96.8386 17.595C96.8386 18.7444 97.8795 19.3343 99.586 19.3343C102.131 19.3343 103.865 18.0669 103.865 16.1222Z"
          fill="white"
        />
        <path
          d="M110.459 18.8938L113.12 16.5951C114.479 18.2459 116.532 19.1298 119.539 19.1298C121.562 19.1298 122.459 18.6873 122.459 17.7739C122.459 16.9491 122.025 16.6246 120.377 16.4476L116.531 16.0642C113.003 15.6218 111.326 14.1195 111.326 11.5258C111.326 8.51927 114.045 6.78101 118.902 6.78101C122.401 6.78101 125.235 7.78287 126.854 9.5811L124.54 11.9978C123.065 10.642 121.244 9.99404 118.641 9.99404C116.733 9.99404 115.836 10.3775 115.836 11.2319C115.836 11.9388 116.356 12.2927 117.686 12.4402L121.937 12.8826C125.494 13.2956 126.911 14.7979 126.911 17.3915C126.911 20.5151 124.337 22.4018 119.306 22.4018C115.286 22.4018 112.25 21.1934 110.458 18.8948L110.459 18.8938Z"
          fill="white"
        />
        <path
          d="M128.213 18.8938L130.874 16.5951C132.233 18.2459 134.286 19.1298 137.293 19.1298C139.317 19.1298 140.213 18.6873 140.213 17.7739C140.213 16.9491 139.779 16.6246 138.131 16.4476L134.285 16.0642C130.757 15.6218 129.08 14.1195 129.08 11.5258C129.08 8.51927 131.799 6.78101 136.656 6.78101C140.155 6.78101 142.989 7.78287 144.608 9.5811L142.295 11.9978C140.82 10.642 138.998 9.99404 136.396 9.99404C134.487 9.99404 133.59 10.3775 133.59 11.2319C133.59 11.9388 134.11 12.2927 135.441 12.4402L139.691 12.8826C143.248 13.2956 144.665 14.7979 144.665 17.3915C144.665 20.5151 142.091 22.4018 137.06 22.4018C133.04 22.4018 130.005 21.1934 128.212 18.8948L128.213 18.8938Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_4883">
          <rect width="144.667" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GympassLogo;
