"use client";

import * as Styled from "./styles";
import { useEffect, useState } from "react";
import { DynamicSessionTabs } from "./DynamicSessionTabs";
import { DynamicSessionContent } from "./DynamicSessionContent";
import { useDispatchEvent } from "@/app/lib/segment";
import { useParams, useSearchParams } from "next/navigation";
import { setUserTabById } from "@/app/_server-actions/userType";

const BodyDynamicListByAllCategoriesAndSections: React.FC<
  BodyDynamicListByAllCategoriesAndSectionsWidget["payload"]
> = (props) => {
  const searchParams = useSearchParams();
  const params = useParams<{ region: Locale }>();
  const { dispatchAnalycts } = useDispatchEvent();

  const getActiveTabBySearchParams = () => {
    const tabReadableId = searchParams.get("tab");

    if (tabReadableId) {
      const selectedTab = props.sessions.find(
        (session) => session.readable_id === tabReadableId
      );

      if (selectedTab) return selectedTab;
    }
  };

  const getActiveTab = (): ActiveBodyDynamicList => {
    const activeTabBySearchParams = getActiveTabBySearchParams();
    if (activeTabBySearchParams) return activeTabBySearchParams;

    const activeTabByCookies = props.activeSession;
    if (activeTabByCookies) return activeTabByCookies;

    return props.sessions[0];
  };

  const [activeSession, setActiveSession] = useState<ActiveBodyDynamicList>(
    getActiveTab()
  );

  useEffect(() => {
    if (activeSession) setUserTabById(params.region, activeSession.id);
  }, [params.region, activeSession]);

  const updateActiveSession = (session: ActiveBodyDynamicList) => {
    setActiveSession(session);
    dispatchAnalycts({
      type: "track",
      event: "hc_tribe_tab",
      properties: {
        tribe_title: session.title,
      },
    });
  };

  return (
    <Styled.Container data-cy="body-dynamic-list-by-all-categories-and-sessions">
      <Styled.Wrapper d="flex" flexDirection="column">
        <DynamicSessionTabs
          sessions={props.sessions}
          activeSession={activeSession}
          updateActiveSession={updateActiveSession}
        />

        <DynamicSessionContent activeSession={activeSession} />
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default BodyDynamicListByAllCategoriesAndSections;
