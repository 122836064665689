import styled from "styled-components";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";

export const Container = styled.div`
  width: 100%;

  .mobile-dynamic-list-session-tabs {
    display: none;
    z-index: 2;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    .desktop-dynamic-list-session-tabs {
      display: none;
    }
    .mobile-dynamic-list-session-tabs {
      display: block;
    }
  }
`;
