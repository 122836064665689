import styled from "styled-components";
import { theme } from "@gympass/yoga";


export const Form = styled.form`
  display: flex;
  gap: ${theme.spacing.xsmall}px;
  padding: 0 0 0 ${theme.spacing.small}px;
  border-radius: 8px;
  border: 1px solid #9898a6;
  max-width: 26.75rem;
`;


export const ScreenReaderLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export const Input = styled.input`
  border: none;
  flex-grow: 2;

  &:focus,
  &:focus {
    outline: none;
  }
`;