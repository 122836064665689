import styled from "styled-components";
import { Text, theme } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import { Section } from "@/app/_components/Section";

export const Wrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.small}px auto;
`;

export const Title = styled(Text.Display1).attrs({
  marginBottom: "large",
})`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.xlarge}px;
    line-height: ${theme.lineHeights.xlarge}px;
  }
`;

export const Subtitle = styled(Text.H5).attrs({
  marginBottom: "xsmall",
})`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.medium}px;
    line-height: ${theme.lineHeights.medium}px;
  }
`;
