import { DynamicSessionContentProps } from "../";
import { Box } from "@gympass/yoga";
import * as Styled from "./styles";
import { ArticleList } from "@/app/_components/ArticleList";

export const DesktopDynamicSessionContent: React.FC<
  DynamicSessionContentProps
> = (props) => {
  return (
    <Styled.SessionContent
      data-cy="desktop-dynamic-session-content"
      className="desktop-dynamic-session-content"
    >
      {props.activeSession?.children?.map((child, childIndex) => (
        <Box key={childIndex} d="flex" flexDirection="column" tabIndex={0}>
          <ArticleList
            title={child.title}
            articles={child.articles}
            eventTracker={{
              type: "track",
              event: "hc_all_article",
            }}
          />
        </Box>
      ))}
    </Styled.SessionContent>
  );
};
