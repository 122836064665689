import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Input, Text } from "@gympass/yoga";
import { Paperclip, Upload2 } from "@gympass/yoga-icons";
import * as Styled from "./styles";
import { handleUploadFile } from "@/app/_server-actions/form/ticket-form/handleUploadFile";
import { useParams } from "next/navigation";

export const FileSelector: React.FC<{
  full: boolean;
  label: string;
  error: string | undefined;
  formId: string;
  handleUploadFiles: (value: string) => void;
}> = (props) => {
  const { formId, handleUploadFiles } = props;
  const [uploadedFilesIds, setUploadedFilesIds] = useState({});
  //TODO: handle errors
  const [uploadErrors, setUploadErrors] = useState<Error[]>([]);

  const params = useParams();
  const locale = params.region as string;

  const handleFileUpload = async (files: File[]) => {
    try {
      const uploadResponses = await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("formId", formId);
          formData.append("locale", locale);

          return handleUploadFile(formData);
        })
      );

      let newUploadedFilesIds = uploadedFilesIds;

      for (const response of uploadResponses) {
        if ("data" in response) {
          newUploadedFilesIds = {
            ...newUploadedFilesIds,
            [response?.data?.fileId!]: response?.data?.fileName,
          };
        }
      }

      setUploadedFilesIds(newUploadedFilesIds);

      const fileIds = Object.keys(newUploadedFilesIds);
      if (fileIds) {
        const idsWithCommma =
          fileIds.length > 1 ? fileIds.join(",") : fileIds[0];

        handleUploadFiles(idsWithCommma);
      }
    } catch (error) {
      console.log("Error uploading files", error);

      if (error instanceof Error) {
        console.log("Error uploading files", error);
        setUploadErrors([error]);
      } else {
        console.log("Unknown error uploading files", error);
        setUploadErrors([new Error("An unknown error occurred")]);
      }
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxSize: 32 * 1024 * 1024,
      multiple: true,
      maxFiles: 5,
      onDrop: (acceptedFiles) => {
        handleFileUpload(acceptedFiles);
      },
    });

  return (
    <Styled.Container>
      <Styled.Wrapper
        {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      >
        <Input {...getInputProps()} full />
        <Icon as={Upload2} width="large" height="large" fill="staminaNew" />

        <Text.Body2 color="deepNew">{props.label}</Text.Body2>
      </Styled.Wrapper>

      {uploadedFilesIds && (
        <div>
          {Object.entries(uploadedFilesIds).map(([id, name], index) => (
            <Styled.FileWrapper key={index}>
              <Icon as={Paperclip} width="small" height="small" />
              <Text.Body2>{name}</Text.Body2>
            </Styled.FileWrapper>
          ))}
        </div>
      )}
    </Styled.Container>
  );
};
