import { DynamicSessionTitlesProps } from "../index";
import * as Styled from "./styles";
import { useCallback } from "react";

export const DesktopDynamicSessionTitles: React.FC<DynamicSessionTitlesProps> = (
props
) => {
  const isActive = useCallback(
    (sessionTitle: string) => props.activeSession.title === sessionTitle,
    [props.activeSession.title]
  );

  return (
    <Styled.Container className="desktop-session-titles">
      {props.sessions.map((session, index) => (
        <Styled.ButtonWrapper key={index} $isActive={isActive(session.title)}>
          <Styled.CustomButton
            $isActive={isActive(session.title)}
            onClick={() => props.updateActiveSession(session)}
            data-cy="desktop-session-title"
            aria-label={
              props.activeSession.title === session.title
                ? `Selected tab: ${session.title}` // TODO: colocar o texto "selected tab" vindo da API para acessibilidade
                : session.title
            }
            title={session.title}
          >
            <Styled.ButtonText
              color={isActive(session.title) ? "primary" : "deep"}
              fontSize="xsmall"
              lineHeight="xsmall"
            >
              {session.title}
            </Styled.ButtonText>
          </Styled.CustomButton>
        </Styled.ButtonWrapper>
      ))}
    </Styled.Container>
  );
};
