"use client";

import { useState } from "react";
import * as Styled from "./styles";
import { ChevronRight } from "@gympass/yoga-icons";
import { StaticSessionTitles } from "./StaticSessionTitles";
import { useDispatchEvent } from "@/app/lib/segment";
import { useRouter } from "next/navigation";

const BodyStaticListBySession: React.FC<BodyStaticListBySession["payload"]> = (
  props
) => {
  const [activeSession, setActiveSession] = useState<Sessions>(
    props.sessions[0]
  );
  const router = useRouter();
  const { dispatchAnalycts } = useDispatchEvent();

  const updateActiveSession = (session: Sessions) => {
    setActiveSession(session);
    dispatchAnalycts({
      type: "track",
      event: "hc_tribe_tab",
      properties: {
        tribe_title: session.title,
      },
    });
  };

  const goToPromotedArticle = (articleTitle: string, articleUrl: string) => {
    dispatchAnalycts({
      type: "track",
      // TODO: change this event name because is not promoted article anymore
      event: "hc_promoted_article",
      properties: {
        promoted_article_title: articleTitle,
      },
    });

    router.push(articleUrl.toLowerCase());
  };

  const goToSeeMoreArticles = (buttonUrl: string) => {
    dispatchAnalycts({
      type: "track",
      // TODO: change this event name because is not promoted article anymore
      event: "hc_see_more_promoted_articles",
      properties: {
        // TODO: get page by path
        current_section: "HOMEPAGE",
        active_session: activeSession.title,
      },
    });

    router.push(buttonUrl);
  };

  return (
    <Styled.Container data-cy="body-static-list-by-session">
      <Styled.Wrapper d="flex" flexDirection="column">
        <Styled.SessionTitle
          aria-label={props.title}
          data-cy="static-list-title"
        >
          {props.title}
        </Styled.SessionTitle>

        <Styled.SessionWrapper className="tab">
          <StaticSessionTitles
            {...props}
            activeSession={activeSession}
            updateActiveSession={updateActiveSession}
          />

          <Styled.SessionContent data-cy="static-list-session-content">
            {activeSession.items.map((item, index) => (
              <Styled.LinkContainer key={index}>
                <Styled.LinkTextContainer
                  title={item.text}
                  onClick={() => {
                    goToPromotedArticle(item.text, item.url);
                  }}
                >
                  <Styled.LinkText
                    fontWeight="regular"
                    lineHeight="medium"
                    fontSize="medium"
                    textAlign="start"
                  >
                    {item.text}
                  </Styled.LinkText>
                </Styled.LinkTextContainer>
              </Styled.LinkContainer>
            ))}
          </Styled.SessionContent>
        </Styled.SessionWrapper>

        <Styled.ButtonContainer d="flex" justifyContent="center">
          <Styled.IconButton
            icon={ChevronRight}
            onClick={() => goToSeeMoreArticles(props.button.url)}
            data-cy="static-list-button"
          >
            {props.button.title}
          </Styled.IconButton>
        </Styled.ButtonContainer>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default BodyStaticListBySession;
