import styled from "styled-components";
import { Box, Button, Text, theme } from "@gympass/yoga";
import {
  MOBILE_MAX_WIDTH_REM,
  DESKTOP_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import Link from "next/link";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  display: flex;
  justify-content: center;
  margin: ${theme.spacing.xxxsmall}px auto 0;
`;

export const Wrapper = styled(Box)`
  width: ${DESKTOP_MAX_WIDTH_REM};
`;

export const SessionWrapper = styled(Box)`
  width: 100%;
  margin-top: ${theme.spacing.xlarge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-top: ${theme.spacing.xsmall}px;
  }
`;

export const SessionTitle = styled(Text.H3).attrs({ bold: true })`
  font-size: ${theme.fontSizes.xxlarge}px;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: 1.25rem;
  }
`;

export const SessionContent = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${theme.spacing.xlarge}px;
  row-gap: ${theme.spacing.large}px;
  align-items: stretch;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LinkContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.light};
  padding-bottom: ${theme.spacing.large}px;
`;

export const LinkTextContainer = styled(Link)`
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
`;

export const LinkText = styled(Text.Body1).attrs({ fontWeight: "semiBold" })`
  text-decoration: underline;
`;

export const ButtonContainer = styled(Box)`
  margin-top: ${theme.spacing.xlarge}px;
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.white};
`;

export const IconButton = styled(Button)`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing.xsmall}px;

  svg {
    margin-right: 0;
  }
`;
