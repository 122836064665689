import { StaticSessionTitlesProps } from "@/app/_widgets/body-static-list-by-session/StaticSessionTitles";
import * as Styled from "./styles";
import { useCallback } from "react";

export const DesktopStaticSessionTitles: React.FC<StaticSessionTitlesProps> = (
  props
) => {
  const isActive = useCallback(
    (sessionTitle: string) => props.activeSession.title === sessionTitle,
    [props.activeSession.title]
  );

  return (
    <Styled.Container
      className="desktop-session-titles"
      data-cy="desktop-session-titles"
    >
      {props.sessions.map((session, index) => (
        <Styled.ButtonWrapper key={index} $isActive={isActive(session.title)}>
          <Styled.CustomButton
            $isActive={isActive(session.title)}
            onClick={() => props.updateActiveSession(session)}
            aria-label={
              props.activeSession.title === session.title
                ? `Selected tab: ${session.title}` // TODO: colocar o texto "selected tab" vindo da API para acessibilidade
                : session.title
            }
            title={session.title}
          >
            <Styled.ButtonText
              color={isActive(session.title) ? "primary" : "deep"}
              fontSize="xsmall"
              lineHeight="xsmall"
            >
              {session.title}
            </Styled.ButtonText>
          </Styled.CustomButton>
        </Styled.ButtonWrapper>
      ))}
    </Styled.Container>
  );
};
