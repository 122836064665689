import React, { createContext, useState } from "react";
import { Snackbar } from "@gympass/yoga";

export const DEFAULT_SNACKBAR_DURATION_IN_MS = 5000;

export const NOTIFICATION_SUBSCRIBERS = [
  {
    key: "snackbar",
    Component: Snackbar,
    mapProps: {
      message: "message",
      type: "variant",
      duration: "duration",
      onAction: "onAction",
      actionLabel: "actionLabel",
      onClose: "onClose",
      icon: "icon",
    },
    defaultProps: { open: true },
  },
];

type NotificationContextType = {
  notification: Function;
  setNotification: Function;
  clearNotification: Function
}

export const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType);  

export interface Subscriber {
  key: string;
  Component: React.FC<React.PropsWithChildren<unknown>>;
  mapProps: Record<string, string>;
  defaultProps: Record<string, unknown>;
}

export type SubscriberParamsType = "success" | "failure" | "info";

export interface SubscriberParams {
  key: string;
  message: string;
  type?: SubscriberParamsType;
  icon?: React.ReactNode;
  duration?: number;
  onAction?: Function;
  actionLabel?: string;
  onClose?: (name?: string) => void;
}

const toProps = (
  subscriber: Subscriber,
  notification: { params: Record<string, unknown> }
) =>
  Object.keys(notification.params).reduce((current, key) => {
    return {
      ...current,
      [subscriber.mapProps[key]]: notification.params[key],
    };
  }, subscriber.defaultProps);

export const NotificationProvider: React.FC<
    React.PropsWithChildren<{
        subscribers: Subscriber[];
    }>
> = ({ subscribers, children }) => {
    const [notification, setNotification] = useState<any>(null);

    const clearNotification = () => {
        setNotification(null);
    };

    const subscriber =
        notification &&
        subscribers.find(
            (subscriber: Subscriber) =>
                subscriber.key === notification.target || !notification.target
        );

    return (
        <NotificationContext.Provider
            value={{ notification, setNotification, clearNotification }}
        >
            {children}
            {subscriber && (
                <subscriber.Component
                    key={notification.key}
                    {...toProps(subscriber, notification)}
                />
            )}
        </NotificationContext.Provider>
    );
};
