"use client";

import { Dropdown } from "@gympass/yoga";
import { DynamicSessionTitlesProps } from "../index";

export const MobileDynamicSessionTitles: React.FC<DynamicSessionTitlesProps> = (
  props
) => {
  return (
    <Dropdown
      full
      className="mobile-session-titles"
      options={props.sessions.map((session) => ({
        label: session.title,
        value: session.title,
        selected: props.activeSession.title === session.title,
      }))}
      onChange={(e: DropdownOnChange) =>
        props.updateActiveSession(
          props.sessions.filter((session) => session.title === e.value)[0]
        )
      }
      {...props}
    />
  );
};
