"use client";
import Svg from "@/app/_widgets/svg";
import * as Styled from "./styles";
import { Box, Text, Menu, Button, Icon } from "@gympass/yoga";
import { ChevronDown, UserFilled, LogOut } from "@gympass/yoga-icons";
import {
  DEFAULT_COUNTRY,
  SUPPORTED_COUNTRIES,
} from "@/app/_constants/country-map";
import { useParams } from "next/navigation";
import { useDispatchEvent } from "@/app/lib/segment";
import { login, logout } from "@/app/_services/keycloak";
import { useMemo } from "react";

const LOGO_MAP: Record<Logo, React.ReactNode> = {
  Gympass: <Svg.HelpCenterLogo />,
  Wellhub: <Svg.WellhubBlackLogo />,
};

const HeaderDynamicDefaultHeader: React.FC<
  HeaderDynamicDefaultHeader["payload"] & {
    behaviour: HeaderDynamicDefaultHeaderBehaviour;
  }
> = (props) => {
  const params = useParams<{ region: string }>();
  const { dispatchAnalycts } = useDispatchEvent();

  const handleClickLogo = () => {
    dispatchAnalycts({
      type: "track",
      event: "hc_header_logo",
      properties: {
        current_section: "HOMEPAGE",
      },
    });
  };

  const handleSelectRegion = (country: {
    id: string;
    name: string;
    icon: any;
    language: string;
  }) => {
    dispatchAnalycts({
      type: "track",
      event: "hc_click_language_selector",
      properties: {
        current_section: "HOMEPAGE",
        selected_language: country.name,
        destinyUrl: `/${country.language}`,
      },
    });
    // TODO: Refresh client-side components when router.push()
    // router.push(`/${country.language}-${country.id}`);

    window.location.href = `${window.location.origin}/${country.language}-${country.id}`;
  };

  const handleLogin = () => {
    if (window.keycloakInstance?.login) {
      login();
    } else {
      handleLogout();
      login();
    }
  };

  const handleLogout = () => {
    logout({ clientId: props.clientId, authUrl: props.authUrl });
  };

  const isLogged = props.behaviour === "LOGGED";

  const selectedRegion = useMemo(() => {
    const [_, country] = params.region.split("-");
    return (
      SUPPORTED_COUNTRIES.find((region) => region.id === country) ??
      DEFAULT_COUNTRY
    );
  }, [params.region]);

  return (
    <Styled.Header data-cy="header">
      <Box d="flex" alignItems="center" gap="xxsmall">
        <Styled.ButtonLink
          href={`/${params.region}`}
          onClick={() => handleClickLogo()}
          data-cy="click-logo"
        >
          {LOGO_MAP[props.logoImage] ?? <Svg.HelpCenterLogo />}
          <Text> {props.title}</Text>
        </Styled.ButtonLink>
      </Box>

      <Box d="flex" alignItems="center" gap="small">
        {/* <Menu onMouseHover={false}>
          <Menu.Action>
            <Styled.ButtonIcon icon={Search} small>
              {""}
            </Styled.ButtonIcon>
          </Menu.Action>
            <Menu.List side="bottom" align="end" id="SearchMenuList">
              <Styled.SearchMenuItem>
                <Text.Medium> Search for keywords or describe your problem </Text.Medium>
                <SearchBar placeholder="teste" description="teste" />
              </Styled.SearchMenuItem>
            </Menu.List>
        </Menu> */}

        <Menu onMouseHover={false}>
          <Menu.Action>
            <Styled.FlagButton
              title="Click to select the region"
              data-cy="flag-button"
            >
              <Icon as={selectedRegion.icon} width="xlarge" height="xlarge" />
            </Styled.FlagButton>
          </Menu.Action>
          <Styled.FlagMenu>
            {SUPPORTED_COUNTRIES.map((country) => (
              <Styled.FlagButtonLink
                data-cy="flag-menu"
                key={country.id}
                onClick={() => handleSelectRegion(country)}
              >
                <Styled.MenuItem
                  active={country.id === selectedRegion.id}
                  icon={country.icon}
                >
                  {country.name}
                </Styled.MenuItem>
              </Styled.FlagButtonLink>
            ))}
          </Styled.FlagMenu>
        </Menu>

        <Menu onMouseHover={false}>
          {isLogged ? (
            <>
              <Menu.Action>
                <Styled.LoggedButtonContainer>
                  <Styled.UserButton
                    className="logged-user-button"
                    d="flex"
                    alignItems="center"
                    gap="xxxsmall"
                    data-cy="user-button"
                    inverted
                  >
                    <Styled.AvatarCircle small>
                      <Text.Body1 bold color="primary">
                        {props.userFirstLetter}
                      </Text.Body1>
                    </Styled.AvatarCircle>
                    <Styled.UserName> {props.userName} </Styled.UserName>
                    <Styled.ArrowDownIcon
                      as={ChevronDown}
                      width="medium"
                      height="medium"
                      fill="primary"
                    />
                  </Styled.UserButton>
                </Styled.LoggedButtonContainer>
              </Menu.Action>
              <Menu.List align="end">
                <Styled.LogoutMenuItem
                  data-cy="logout-menu-item"
                  onClick={handleLogout}
                  icon={LogOut}
                >
                  {props.menu.logged[0].title}
                </Styled.LogoutMenuItem>
              </Menu.List>
            </>
          ) : (
            <Menu.Action>
              <Styled.UnloggedButtonContainer>
                <Button.Outline
                  className="unlogged-user-button"
                  icon={UserFilled}
                  small
                  title={props.menu.unlogged[0].title}
                  //TODO: get the onClick by action name from response
                  onClick={handleLogin}
                  data-cy="user-icon"
                >
                  {""}
                </Button.Outline>
              </Styled.UnloggedButtonContainer>
            </Menu.Action>
          )}
        </Menu>
      </Box>
    </Styled.Header>
  );
};

export default HeaderDynamicDefaultHeader;
