import { theme, Text } from "@gympass/yoga";
import styled from "styled-components";
import NextLink from "next/link";

export const Container = styled.div`
  background-color: ${theme.colors.clearNew};
  padding: 1rem;
  border-radius: ${theme.radii.regular}px;
  margin: ${theme.spacing.small}px auto ${theme.spacing.xxlarge}px auto;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Text.H3).attrs({ bold: true })`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  margin-block-end: 0.5rem;
`;

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 0rem;
`;

export const List = styled.li`
  list-style: none;
  padding: 1.5rem auto;
`;

export const Link = styled(NextLink)`
  color: ${theme.colors.primary};
  font-weight: 500;
  line-height: 1.5rem;
`;
