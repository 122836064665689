import { Banner } from "@/app/_components/Banner";
import { Time } from "@gympass/yoga-icons";
import { Text } from "@gympass/yoga";
import * as Styled from "../styles";

export const WorkingHoursBanner: React.FC<
  BodyDynamicTicketFormWidget["payload"]
> = (props) => {
  return (
    <Banner>
      <Styled.BannerBox d="flex" alignItems="center">
        <Styled.BannerIcon as={Time} width="small" height="medium" />
        <Styled.BannerText>
          <Text bold>{props.workingHoursbanner.title}</Text>
          <Text fontSize="small">{props.workingHoursbanner.subtitle}</Text>
        </Styled.BannerText>
      </Styled.BannerBox>
    </Banner>
  );
};
