"use client";
import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import { ArticleList } from "@/app/_components/ArticleList";
import { useDispatchEvent } from "@/app/lib/segment";
import ArtcileEvaluation from "./ArticleEvaluation";
import { setArticleEvaluation } from "@/app/_server-actions/cookies/articleEvaluation";
import useSnowplow from "@/app/_hooks/useSnowplow";

export enum ARTICLE_EVALUATION_EVENT {
  yes = 1,
  no = -1,
}

const BodyDynamicArticleWithRelatedArticles: React.FC<
  BodyDynamicArticleWithRelatedArticlesWidget["payload"]
> = (props) => {
  const { eventDispatcher } = useSnowplow();
  const { dispatchAnalycts } = useDispatchEvent();
  const [isVoted, setIsVoted] = useState(props.article_evaluation.isVoted);

  useEffect(() => {
    dispatchAnalycts({
      type: "track",
      event: "load_article_new_hc",
      properties: {
        external_id: props.external_id,
      },
    });
  }, [props.external_id, dispatchAnalycts]);

  const handleAccordions = (articleContent: Element | null) => {
    const openAccordion = (event: Event) => {
      const target = event.target as HTMLElement;
      const clickedTitle = target?.closest(".accordion__item-title");

      if (clickedTitle) {
        const accordion = clickedTitle.closest(".accordion__item");
        accordion?.classList.toggle("active");
      }
    };

    articleContent?.addEventListener("click", openAccordion);

    return () => {
      articleContent?.removeEventListener("click", openAccordion);
    };
  };

  const handleTabs = (articleContent: Element | null) => {
    const showActiveTab = (event: Event) => {
      const target = event?.target as HTMLElement;
      const clickedTab = target?.closest(".tabs-link");

      if (clickedTab) {
        const tabMenus = articleContent?.querySelectorAll(".tabs-link");
        const tabContents = articleContent?.querySelectorAll(".tab");

        tabMenus?.forEach((menu, index) => {
          const tabContent = tabContents?.[index];
          const isActive = menu === clickedTab;

          menu.classList.toggle("is-active", isActive);
          tabContent?.classList.toggle("is-hidden", !isActive);
        });
      }
    };

    articleContent?.addEventListener("click", showActiveTab);

    return () => {
      articleContent?.removeEventListener("click", showActiveTab);
    };
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const articleContent = document.querySelector(".article-content");

      handleAccordions(articleContent);
      handleTabs(articleContent);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const sendEventsArticleEvaluationEvents = (
    evaluation: ARTICLE_EVALUATION_EVENT
  ) => {
    const commomData = {
      article_id: props.id,
      article_title: props.readable_id,
      article_external_id: props.external_id,
    };

    eventDispatcher({
      action: "click",
      category: "page_usage",
      label: "button",
      property: "article_evaluation",
      value: evaluation,
      ...commomData,
    });

    dispatchAnalycts({
      type: "track",
      event: "hc_article_evaluation",
      properties: {
        was_helpful: evaluation,
        ...commomData,
      },
    });
  };

  const handleArticleEvaluation = (evaluation: ARTICLE_EVALUATION_EVENT) => {
    setIsVoted(true);
    setArticleEvaluation(props.external_id);
    sendEventsArticleEvaluationEvents(evaluation);
  };

  return (
    <Styled.Container>
      <div className="left-content">
        <Styled.Title className="article-title">{props.title}</Styled.Title>
      </div>
      <Styled.Wrapper>
        <div className="article-content" id={props.id}>
          <div dangerouslySetInnerHTML={{ __html: props.properties.raw }} />

          <ArtcileEvaluation
            article_evaluation={props.article_evaluation}
            external_id={props.external_id}
            isVoted={isVoted}
            handleArticleEvaluation={handleArticleEvaluation}
          />
        </div>
        <Styled.RelatedArticlesContainer>
          <ArticleList
            title={props.related_articles.title}
            articles={props.related_articles.articles}
            eventTracker={{
              type: "track",
              event: "hc_click_related_articles",
            }}
          />
        </Styled.RelatedArticlesContainer>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default BodyDynamicArticleWithRelatedArticles;
