import styled from "styled-components";
import { Box } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";

export const ImageContent = styled(Box).attrs({
  display: "flex",
})`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    max-width: 26.75rem;
    justify-content: center;
  }
`;
