import styled from "styled-components";
import { theme, Box, Text } from "@gympass/yoga";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import Link from "next/link";

export const Footer = styled.footer`
  margin-top: auto;
`;

export const Wrapper = styled.div`
  background-color: ${theme.colors.stamina};
  padding: ${theme.spacing.huge}px 0;
  margin-top: ${theme.spacing.xxxlarge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.medium}px 0;
    margin-top: ${theme.spacing.xxlarge}px;
  }
`;

export const FooterWrapper = styled(Box)`
  margin: 0 auto;
  max-width: ${DESKTOP_MAX_WIDTH_REM};
  padding: 0 ${theme.spacing.xhuge}px;
  gap: ${theme.spacing.xxlarge}px;

  @media (min-width: ${DESKTOP_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.small}px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.medium}px;
  }
`;

export const FooterLogoWrapper = styled(Box)`
  margin-bottom: 2.9375rem;
`;

export const FooterLogoText = styled(Text.Small).attrs({
  color: "white",
})``;

export const FooterMenu = styled(Box)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    flex-direction: column;
  }
`;

export const FooterLinks = styled(Box)`
  display: flex;
  gap: ${theme.spacing.xlarge}px;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    flex-direction: column;
  }
`;

export const FooterLink = styled(Link)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: ${theme.colors.white};
`;

export const FooterSocialMedias = styled(Box)``;

export const FooterStores = styled(Box)`
  margin-top: ${theme.spacing.large}px;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-top: 2.9375rem;
  }
`;

export const LinkText = styled(Text).attrs({
  color: "white",
})``;
