
import {
  DEFAULT_SNACKBAR_DURATION_IN_MS,
  NotificationContext,
  SubscriberParams,
} from "@/app/_providers/NotificationProvider";
import { useContext } from "react";

function isFunction(e: unknown): e is Function {
  return typeof e === "function";
}

const useNotification = () => {
  const { setNotification, clearNotification } =
    useContext(NotificationContext);

  const notificate = (params: SubscriberParams) => {
    const timeoutFadeOut = 50;

    const newNotification = {
      params: {
        ...params,
        onClose: () => {
          clearNotification();

          const paramsOnClose = params?.onClose;
          if (paramsOnClose) {
            paramsOnClose();
          }
        },
        duration: params.duration
          ? params.duration - timeoutFadeOut
          : DEFAULT_SNACKBAR_DURATION_IN_MS,
      },
    };

    isFunction(clearNotification) && clearNotification();

    setTimeout(() => {
      isFunction(setNotification) && setNotification(newNotification);
    }, timeoutFadeOut);
  };

  return {
    notificate,
  };
};

export default useNotification;
