"use client";
import * as Styled from "@/app/_widgets/ui-static-multiple-simple-banner/styles";
import { useDispatchEvent } from "@/app/lib/segment";

const SimpleBanner = (props: SimpleBanner) => {
  const { dispatchAnalycts } = useDispatchEvent();

  const dispatchButtonRedirectEvent = () => {
    dispatchAnalycts({
      type: "track",
      event: "hc_ui_static_multiple_simple_banner_button",
      properties: {
        bannerTitle: props.title,
        bannerSubtitle: props.subtitle,
        destinyUrl: props.button.url,
        buttonLabel: props.button.title,
      },
    });
  };

  return (
    <Styled.BannerContainer
      flexDirection="column"
      justifyContent="center"
      borderRadius="small"
      backgroundColor="clear"
      data-cy="simple-banner"
    >
      <Styled.BannerTitle data-cy="simple-banner-title">
        {props.title}
      </Styled.BannerTitle>
      <Styled.BannerSubtitle mb="xlarge" data-cy="simple-banner-subtitle">
        {props.subtitle}
      </Styled.BannerSubtitle>
      <Styled.BannerButton
        secondary
        data-cy="simple-banner-button"
        onClick={() => dispatchButtonRedirectEvent()}
      >
        <Styled.LinkButton href={props.button.url}>
          {props.button.title}
        </Styled.LinkButton>
      </Styled.BannerButton>
    </Styled.BannerContainer>
  );
};

const UiStaticMultipleSimpleBanner: React.FC<
  UiStaticMultipleSimpleBannerWidget["payload"]
> = ({ banners }) => (
  <Styled.Container>
    <Styled.Banners data-cy="ui-static-multiple-simple-banner">
      {banners?.map((banner, index) => (
        <SimpleBanner {...banner} key={index} />
      ))}
    </Styled.Banners>
  </Styled.Container>
);

export default UiStaticMultipleSimpleBanner;
