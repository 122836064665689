"use client";

import { useCallback, useState } from "react";
import * as Styled from "./styles";
import { Button } from "@gympass/yoga";
import { Search } from "@gympass/yoga-icons";
import { useDispatchEvent } from "@/app/lib/segment";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import env from "@/config/env";

type SearchComponent = {
  description: string;
  placeholder: string;
  buttonTitle: string;
};

const SearchBar = (props: SearchComponent) => {
  const router = useRouter();
  const pathname = usePathname();
  const { dispatchAnalycts } = useDispatchEvent();

  const searchParams = useSearchParams();
  const query = searchParams.get("query") || "";

  const [search, setSearch] = useState<string>(query);

  const { region, source } = useParams<{ region: string, source?: string }>();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback((event) => {
    event.preventDefault();

    if (search) {
      dispatchAnalycts({
        type: "track",
        event: "hc_searchbar",
        properties: {
          query: search,
          source,
        },
      });

      const queryParams = new URLSearchParams({
        query: search
      });

      if (pathname === `/${region}`) {
        queryParams.append("source", "home");
      } else if (source) {
        queryParams.append("source", source);
      } else if (pathname.includes("search-results")) {
        queryParams.append("source", "search-results");
      } else {
        queryParams.append("source", "unknown");
      }

      router.push(
        encodeURI(
          `${env.api.omnitrixService.url}/${region}/search-results?${queryParams}`
        )
      );
    }
  }, [dispatchAnalycts, pathname, region, router, search, source]);

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.ScreenReaderLabel
        data-cy="simple-search__input-label"
        id="search-label"
        htmlFor="search"
      >
        {props.description}
      </Styled.ScreenReaderLabel>
      <Styled.Input
        type="text"
        placeholder={props.placeholder}
        name="search"
        id="search"
        aria-labelledby="search-label"
        data-cy="simple-search__input"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(event.target.value);
        }}
      />
      <Button.Icon
        icon={Search}
        inverted
        data-cy="simple-search__button"
        type="submit"
        title={props.buttonTitle}
      />
    </Styled.Form>
  );
};

export default SearchBar;
