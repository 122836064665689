import styled from "styled-components";
import { Box, Button, theme } from "@gympass/yoga";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.xsmall}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-top: ${theme.spacing.xxsmall}px;
  }
`;

export const Wrapper = styled(Box)`
  width: ${DESKTOP_MAX_WIDTH_REM};
`;

export const SessionContent = styled(Box)`
  margin-top: ${theme.spacing.xxlarge}px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${theme.spacing.xlarge}px;
  row-gap: ${theme.spacing.large}px;
  align-items: stretch;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ArticleLink = styled(Button.Link)``;
