import { DesktopDynamicSessionTabs } from "./desktop";
import { MobileDynamicSessionTabs } from "./mobile";
import * as Styled from "./styles";

export type DynamicSessionTabsProps = {
  sessions: BodyDynamicListByAllCategoriesAndSectionsWidget["payload"]["sessions"];
  activeSession: AllArticlesPageBuildScreenActionResultData;
  updateActiveSession: (
    session: AllArticlesPageBuildScreenActionResultData
  ) => void;
};

export const DynamicSessionTabs: React.FC<DynamicSessionTabsProps> = (
  props
) => {
  return (
    <Styled.Container data-cy="dynamic-list-session-tabs">
      <DesktopDynamicSessionTabs {...props} />
      <MobileDynamicSessionTabs {...props} />
    </Styled.Container>
  );
};
