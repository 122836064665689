import styled from "styled-components";
import { Text, theme } from "@gympass/yoga";

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${theme.colors.light};
`;

export const ButtonWrapper = styled.div<ActiveSessionProps>`
  border-bottom: 2px solid
    ${({ $isActive }) => ($isActive ? theme.colors.primary : "transparent")};
`;

export const CustomButton = styled.button<ActiveSessionProps>`
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.xlarge}px;
  cursor: pointer;
`;

export const ButtonText = styled(Text.H3)`
  text-transform: uppercase;
  letter-spacing: 1px;
`;
