"use client";

import { MOBILE_MAX_WIDTH_PX } from "@/app/_constants/media-query";
import { useEffect, useState } from "react";

const useDevice = () => {
  const [isMobile, setIsmobile] = useState(false);

  useEffect(() => {
    setIsmobile(window.screen.width < MOBILE_MAX_WIDTH_PX);
  }, []);

  return isMobile;
};

export default useDevice;
