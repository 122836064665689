import styled from "styled-components";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import { Text, theme } from "@gympass/yoga";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: ${theme.spacing.xxxsmall}px auto;
`;

export const Title = styled(Text.Display1)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.xlarge}px;
    line-height: ${theme.lineHeights.xlarge}px;
    margin: 1rem 0 0;
  }
`;

export const Subtitle = styled(Text.H2)`
  margin-top: ${theme.spacing.large}px;
  font-size: ${theme.fontSizes.medium}px;
  line-height: ${theme.lineHeights.xsmall}px;
  font-weight: ${theme.fontWeights.regular};

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-top: ${theme.spacing.large}px;
  }
`;
