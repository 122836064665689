"use client";

import { createApiContext } from "@/app/_server-actions/context";
import { initializeLegacyChatbotAdapters } from "../Chatbot";
import { useParams } from "next/navigation";
import { useEffect } from "react";

const ClientSideInitializer: React.FC<{
  toggles: Feature[];
  experiments: Feature[];
}> = ({ toggles, experiments }) => {
  const params = useParams<{ region: string }>();

  useEffect(() => {
    createApiContext();
    initializeLegacyChatbotAdapters({
      toggles,
      experiments,
      region: params.region,
    });
  }, [params.region, experiments, toggles]);

  return <></>;
};

export default ClientSideInitializer;
