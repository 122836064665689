"use client";

import React from "react";
import * as Styled from "./styles";
import { Text } from "@gympass/yoga";
import { ARTICLE_EVALUATION_EVENT } from "..";

const ArtcileEvaluation: React.FC<{
  article_evaluation: ArticleEvaluation;
  external_id: string;
  isVoted: boolean;
  handleArticleEvaluation: (evaluation: ARTICLE_EVALUATION_EVENT) => void;
}> = (props) => {
  return (
    <Styled.VoteBox>
      {props.isVoted ? (
        <Text> {props.article_evaluation.feedback}</Text>
      ) : (
        <>
          <Text> {props.article_evaluation.question} </Text>
          <Styled.VoteButton
            className="vote-button"
            onClick={() =>
              props.handleArticleEvaluation(ARTICLE_EVALUATION_EVENT.yes)
            }
          >
            {props.article_evaluation.yes}
          </Styled.VoteButton>
          <Styled.VoteButton
            className="vote-button"
            onClick={() =>
              props.handleArticleEvaluation(ARTICLE_EVALUATION_EVENT.no)
            }
          >
            {props.article_evaluation.no}
          </Styled.VoteButton>
        </>
      )}
    </Styled.VoteBox>
  );
};

export default ArtcileEvaluation;
