import styled from "styled-components";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import { theme } from "@gympass/yoga";

export const Container = styled.div`
  width: 100%;

  .mobile-session-titles {
    display: none;
  }

  margin-bottom: ${theme.spacing.large}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    .desktop-session-titles {
      display: none;
    }
    .mobile-session-titles {
      display: block;
    }
  }
`;
