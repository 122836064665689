"use client";
import * as Styled from "./styles";

const BodyStaticPageTitle: React.FC<BodyStaticPageTitleWidget["payload"]> = (
  props
) => {
  return (
    <Styled.Container data-cy="static-page-title">
      <Styled.Title data-cy="title">{props.title}</Styled.Title>
      <Styled.Subtitle data-cy="subtitle" color="deep">
        {props.subtitle}
      </Styled.Subtitle>
    </Styled.Container>
  );
};

export default BodyStaticPageTitle;
