import { articleSuggestion } from "@/app/_server-actions/form/ticket-form/articleSuggestion";
import { Input } from "@gympass/yoga";
import { ChangeEvent, useState } from "react";
import { ArticleSuggestion } from "../../ArticleSuggestion";
import { useParams } from "next/navigation";
import { useDebounce } from "@/app/_utils/useDebounce";

type InputTextProps = {
  full: boolean;
  label: string;
  name: string;
  value: string;
  onBlur: (value: string) => void;
  error: string | undefined;
  onChange: (
    e: ChangeEvent<HTMLInputElement> & {
      value: string;
    }
  ) => void;
  onClean: (cleaned: string) => void;
};

export const InputText: React.FC<InputTextProps> = (props) => {
  const [value, setValue] = useState(props.value);

  const { onBlur } = props;

  return (
    <Input
      {...props}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      onBlur={() => onBlur(value)}
      value={value}
      onClean={() => setValue("")}
    />
  );
};

const SEARCH_TRIGGER_MIN_LENGTH = 3;
const SEARCH_TRIGGER_DEBOUNCE_IN_MS = 1000;

export const InputTextWithArticleSuggestion: React.FC<InputTextProps> = (
  props
) => {
  const { onBlur } = props;
  const [value, setValue] = useState(props.value);
  const [articles, setArticles] = useState<PaginatedArticlesApiResponse>();
  const { region } = useParams<{ region: Locale }>();

  const handleDebounce = useDebounce(async (inputValue: string) => {
    if (inputValue.length >= SEARCH_TRIGGER_MIN_LENGTH) {
      const suggestedArticles = await articleSuggestion(inputValue, region);
      if (suggestedArticles.articles.length) {
        setArticles(suggestedArticles);
      }
    }
  }, SEARCH_TRIGGER_DEBOUNCE_IN_MS);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handleDebounce(e.target.value);
  };

  return (
    <>
      <Input
        {...props}
        onChange={handleChange}
        onBlur={() => onBlur(value)}
        value={value}
        onClean={() => setValue("")}
      />

      {articles && <ArticleSuggestion articles={articles} />}
    </>
  );
};
