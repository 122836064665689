import styled from "styled-components";
import { Box, Text, theme, Button } from "@gympass/yoga";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: ${theme.spacing.xxxsmall}px auto 0;

  @media (min-width: ${MOBILE_MAX_WIDTH_REM}) {
    .left-content {
      max-width: 60%;
    }
  }

  .article-content {
    .tabs-menu {
      border-bottom: 1px solid ${theme.colors.light};
      padding: 8px 0;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .tabs-link {
      cursor: pointer;
      padding: 8px 32px;
      font-family: Inter;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: ${theme.colors.deep};
    }

    .tabs-link.is-active {
      color: ${theme.colors.primary};
      border-bottom: 2px solid ${theme.colors.primary};
    }

    .tab.is-hidden {
      display: none;
    }
  }
`;

export const Title = styled(Text.Display1)`
  margin-bottom: 1rem;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0px;
    margin: 2rem 0 0.5rem;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  max-width: ${DESKTOP_MAX_WIDTH_REM};
  gap: 2rem;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    flex-direction: column;
    gap: 3rem;
  }
`;

export const RelatedArticlesContainer = styled(Box)`
  max-width: 368px;
  display: flex;
  flex-direction: column;
`;

export const VoteBox = styled(Box)`
  margin-top: 40px;
  font-size: 16px;
  display: flex;
  border-top: 1px solid ${theme.colors.light};
  border-bottom: 1px solid ${theme.colors.light};
  padding: 40px 20px;
  gap: 8px;

  .vote-button {
    padding: 0;
    height: 100%;
  }
`;

export const VoteButton = styled(Button.Text).attrs({ secondary: true})``;