"use client";

import { DesktopStaticSessionTitles } from "./desktop";
import { MobileStaticSessionTitles } from "./mobile";
import * as Styled from "./styles";

export type StaticSessionTitlesProps = {
  activeSession: Sessions;
  updateActiveSession: (activeSession: Sessions) => void;
} & BodyStaticListBySession["payload"];

export const StaticSessionTitles: React.FC<StaticSessionTitlesProps> = (
  props
) => {
  return (
    <Styled.Container data-cy="static-list-session-titles">
      <DesktopStaticSessionTitles {...props} />
      <MobileStaticSessionTitles {...props} />
    </Styled.Container>
  );
};
