import styled from "styled-components";
import { Text, theme } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import Link from "next/link";

export const Title = styled(Text.H3).attrs({ bold: true })`
  font-size: ${theme.fontSizes.xxlarge}px;
  line-height: ${theme.lineHeights.xxlarge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.xlarge}px;
    line-height: ${theme.lineHeights.xlarge}px;
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.xlarge}px 0 0;
  gap: ${theme.spacing.small}px;
  list-style-type: none;
  padding: 0;
`;

export const ArticleLink = styled(Link)`
  height: fit-content;
  padding: 0;
`;

export const LinkText = styled(Text.Body1).attrs({ fontWeight: "semiBold" })`
  text-decoration: underline;
`;
