import styled from "styled-components";
import { Box, theme } from "@gympass/yoga";

export const SessionContent = styled(Box)`
  hr {
    border-width: 0;
    border-bottom-width: 1px;
    border-color: ${theme.colors.light};
  }
`;
