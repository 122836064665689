"use client";

import { useEffect, useState } from "react";
import * as Styled from "./styles";
import { ChevronRight } from "@gympass/yoga-icons";
import { useDispatchEvent } from "@/app/lib/segment";
import { useParams } from "next/navigation";
import { DynamicSessionTitles } from "./DynamicSessionTitles";
import { setUserTabById } from "@/app/_server-actions/userType";

const BodyDynamicShowPromotedArticles: React.FC<
  BodyDynamicShowPromotedArticlesWidget["payload"]
> = (props) => {
  const params = useParams<{ region: Locale }>();
  const { dispatchAnalycts } = useDispatchEvent();

  const [activeSession, setActiveSession] =
    useState<SessionWithPromotedArticles>(props.activeSession);

  useEffect(() => {
    if (activeSession) setUserTabById(params.region, activeSession.id);
  }, [params.region, activeSession]);

  if (!props.activeSession) {
    return <></>;
  }

  const updateActiveSession = (session: SessionWithPromotedArticles) => {
    setActiveSession(session);

    dispatchAnalycts({
      type: "track",
      event: "hc_tribe_tab",
      properties: {
        tribe_title: session.title,
      },
    });
  };

  const dispatchGoToArticleEvent = (articleTitle: string) => {
    dispatchAnalycts({
      type: "track",
      event: "hc_promoted_article",
      properties: {
        promoted_article_title: articleTitle,
      },
    });
  };

  const dispatchGoSeeMoreArticlesEvent = (): void => {
    dispatchAnalycts({
      type: "track",
      event: "hc_see_more_promoted_articles",
      properties: {
        // TODO: get page by path
        current_section: "HOMEPAGE",
        active_session: activeSession?.title,
      },
    });
  };

  return (
    <Styled.Container>
      <Styled.Wrapper d="flex" flexDirection="column">
        <Styled.SessionTitle aria-label={activeSession?.title}>
          {props.title}
        </Styled.SessionTitle>

        <Styled.SessionWrapper className="tab">
          {activeSession && (
            <DynamicSessionTitles
              sessions={props.sessions}
              activeSession={activeSession}
              updateActiveSession={updateActiveSession}
            />
          )}

          <Styled.SessionContent>
            {activeSession?.promoted_articles?.map((item, index) => (
              <Styled.LinkContainer key={index}>
                <Styled.LinkTextContainer
                  prefetch={false}
                  href={`/${
                    params.region
                  }/article/${item.readable_id.toLowerCase()}`}
                  title={item.title}
                  data-cy="active-session-container"
                  onClick={() => {
                    dispatchGoToArticleEvent(item.title);
                  }}
                >
                  <Styled.LinkText data-cy="promoted-article-links">
                    {item.title}
                  </Styled.LinkText>
                </Styled.LinkTextContainer>
              </Styled.LinkContainer>
            ))}
          </Styled.SessionContent>
        </Styled.SessionWrapper>

        <Styled.ButtonContainer d="flex" justifyContent="center">
          <Styled.LinkButton
            href={`${props.button.url}/?tab=${activeSession?.readable_id}`}
          >
            <Styled.IconButton
              icon={ChevronRight}
              onClick={() => dispatchGoSeeMoreArticlesEvent()}
            >
              {props.button.title}
            </Styled.IconButton>
          </Styled.LinkButton>
        </Styled.ButtonContainer>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default BodyDynamicShowPromotedArticles;
