import { useCallback } from "react";
import { DynamicSessionTabsProps } from "..";
import * as Styled from "./styles";

export const DesktopDynamicSessionTabs: React.FC<DynamicSessionTabsProps> = (
  props
) => {
  const { activeSession } = props;
  const { title } = activeSession || {};

  const isActive = useCallback(
    (sessionTitle: string) => title === sessionTitle,
    [title]
  );

  return (
    <Styled.TabsWrapper
      d="flex"
      data-cy="desktop-dynamic-list-session-tabs"
      className="desktop-dynamic-list-session-tabs"
    >
      {props.sessions.map((session, index) => (
        <Styled.ButtonWrapper key={index} $isActive={isActive(session.title)}>
          <Styled.ButtonTab
            $isActive={isActive(session.title)}
            onClick={() => props.updateActiveSession(session)}
            aria-label={
              props.activeSession.title === session.title
                ? `Selected tab: ${session.title}` // TODO: colocar o texto "selected tab" vindo da API para acessibilidade
                : session.title
            }
            title={session.title}
            tabIndex={0}
          >
            <Styled.TextTab
              color={isActive(session.title) ? "primary" : "deep"}
              fontSize="xsmall"
              lineHeight="xsmall"
              data-cy="desktop-dynamic-list-session-tab-title"
              tabIndex={0}
            >
              {session.title}
            </Styled.TextTab>
          </Styled.ButtonTab>
        </Styled.ButtonWrapper>
      ))}
    </Styled.TabsWrapper>
  );
};
