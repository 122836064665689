import React, { useCallback } from "react";
import * as Styled from "./styles";
import { dispatchInbentaEvent } from "@/app/_server-actions/redirect-from-search-results-to-article-page";
import { useParams, useRouter } from "next/navigation";
import { useDispatchEvent } from "@/app/lib/segment";

export const ArticleSuggestion: React.FC<{
  articles: PaginatedArticlesApiResponse;
}> = (props) => {
  const { articles } = props.articles;
  const router = useRouter();
  const { dispatchAnalycts } = useDispatchEvent();
  const { region } = useParams<{ region: string }>();

  const handleArticleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      const target = e.target as HTMLAnchorElement;
      const { articleId, inbentaClickCode } = target.dataset;

      if (inbentaClickCode && articleId) {
        dispatchInbentaEvent({ region, articleId, inbentaClickCode });
      }

      dispatchAnalycts({
        type: "track",
        event: "hc_searchbar_article_suggestion",
        properties: {
          href: target.href,
        },
      });

      router.push(target.href);
    },
    [dispatchAnalycts, region, router]
  );

  return (
    articles.length > 0 && (
      <Styled.Container>
        {/* FIXME: Get article title via API */}
        <Styled.Title>Suggested help articles</Styled.Title>
        <Styled.Ul>
          {articles.map((article) => (
            <Styled.List key={`article-${article.id}`}>
              <Styled.Link
                href={`/${region}/articles/${article.readable_id}`}
                data-inbenta-click-id={article.metadata.inbenta_click_code}
                data-article-id={article.id}
                onClick={handleArticleClick}
              >
                {article.title}
              </Styled.Link>
            </Styled.List>
          ))}
        </Styled.Ul>
      </Styled.Container>
    )
  );
};
