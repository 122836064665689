import { theme } from "@gympass/yoga";
import { Button, Text } from "@gympass/yoga";
import { Box } from "@gympass/yoga";
import styled from "styled-components";
import {
  MOBILE_MAX_WIDTH_REM,
  DESKTOP_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";
import Link from "next/link";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  display: flex;
  justify-content: center;
  padding: 0 ${theme.spacing.huge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: 0 ${theme.spacing.medium}px;
  }
`;

export const Banners = styled(Box)`
  display: grid;
  gap: 1rem;
  max-width: ${DESKTOP_MAX_WIDTH_REM};
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    grid-template-columns: 1fr;
  }
`;

export const BannerButton = styled(Button.Outline)`
  cursor: pointer;
  width: fit-content;
`;

export const BannerContainer = styled(Box)`
  padding: ${theme.spacing.xhuge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.medium}px;
  }
`;

export const BannerTitle = styled(Text.H3).attrs({ bold: true })`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: 1.25rem;
  }
`;

export const BannerSubtitle = styled(Text.Body1)`
  line-height: 24px;
`;

export const LinkButton = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;
