"use client";
import { YogaImage } from "@/app/_components/YogaImage";
import * as Styled from "./styles";

export const WithNoArticlesDynamicPaginatedLinkList: React.FC<{
  fallbackImageName: Illustrations;
}> = (props) => {
  return (
    <Styled.ImageContent>
      <YogaImage image={props.fallbackImageName} size="large" />
    </Styled.ImageContent>
  );
};
