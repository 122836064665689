import { Box, Text, theme } from "@gympass/yoga";
import styled from "styled-components";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import Link from "next/link";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  display: flex;
  justify-content: center;
  max-width: 100%;
  background-color: ${theme.colors.sand};
  padding: ${theme.spacing.xxxlarge}px ${theme.spacing.huge}px;
  margin-top: ${theme.spacing.huge}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.xxlarge}px ${theme.spacing.medium}px
      ${theme.spacing.huge}px;
    margin-top: ${theme.spacing.xxlarge}px;
  }
`;

export const BannerTitle = styled(Text.H2).attrs({ bold: true })`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`;

export const Wrapper = styled(Box).attrs({})`
  display: flex;
  width: 100%;
  padding-left: ${theme.spacing.huge}px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: 0;
  }
`;

export const ImageWrapper = styled(Wrapper)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    display: none;
  }
`;

export const LinkButton = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;
