"use client";
import { ArticleList } from "@/app/_components/ArticleList";
import * as Styled from "./styles";

const BodyDynamicListOfLink: React.FC<
  BodyDynamicListOfLinksWidget["payload"]
> = (props) => {
  return (
    <Styled.Container>
      <ArticleList
        articles={props.items}
        eventTracker={{
          type: "track",
          event: "hc_category_article",
        }}
      />
    </Styled.Container>
  );
};

export default BodyDynamicListOfLink;
