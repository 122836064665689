import styled from "styled-components";
import { theme, Box } from "@gympass/yoga";

type VisibleFieldProps = {
  $isVisible: boolean;
};

export const BoxField = styled(Box) <VisibleFieldProps>`
  margin-bottom: ${theme.spacing.xxlarge}px;
  display: ${(props) => (props.$isVisible ? "block" : "none")};
`;

export const BoxButton = styled(Box)`
  margin-top: ${theme.spacing.xxlarge}px;
`;
