"use client";
import { ReactNode } from "react";
import { Banner, Box, theme } from "@gympass/yoga";
import { AlertCircle, AlertTriangle, CheckedFull } from "@gympass/yoga-icons";

const NOTIFICATION_TYPE_ICON_MAP: Record<
  BodyDynamicShowNotificationBanners["payload"]["banners"][number]["type"],
  ReactNode
> = {
  success: CheckedFull,
  informative: AlertCircle,
  attention: AlertTriangle,
};

const BodyDynamicShowNotificationBanners: React.FC<
  BodyDynamicShowNotificationBanners["payload"] & {
    behaviour: BodyDynamicShowNotificationBannersBehaviour;
  }
> = (props) => {
  if (props.behaviour === "HIDDEN") {
    return <></>;
  }
  return (
    <Box d="flex" flexDirection="column">
      {props.banners.map((banner, index) => (
        <Banner
          key={`${banner.type}-${index}`}
          icon={NOTIFICATION_TYPE_ICON_MAP[banner.type]}
          margin="0 16px"
          variant={banner.type}
          message={banner.title}
          data-cy="notification-banner"
        />
      ))}
    </Box>
  );
};

export default BodyDynamicShowNotificationBanners;
