import { Banner } from "@/app/_components/Banner";
import { Text } from "@gympass/yoga";
import * as Styled from "../styles";

export const ProfileBanner: React.FC<
  BodyDynamicTicketFormWidget["payload"] & { getContactReasonUrl: () => string }
> = (props) => {
  const { profile, personas, contactReason } = props.profileBanner;
  const { buttons, readable_id, selectedContactReason, getContactReasonUrl } =
    props;

  return (
    <Banner>
      <Styled.BannerBox
        d="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Styled.BannerText>
          <Text>
            <b>{profile}</b> {personas[readable_id] ?? ""}
          </Text>
          <Text>
            <b>{contactReason}</b> {selectedContactReason}
          </Text>
        </Styled.BannerText>
        <Styled.ButtonLink href={getContactReasonUrl()}>
          {buttons.change}
        </Styled.ButtonLink>
      </Styled.BannerBox>
    </Banner>
  );
};
