import styled from "styled-components";
import { Box, theme } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import MUIPagination from "@mui/material/Pagination";
import MUIPaginationItem from "@mui/material/PaginationItem";

export const Container = styled(Box)`
  .mobile-pagination {
    display: none;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    .mobile-pagination {
      display: block;
    }

    .desktop-pagination {
      display: none;
    }
  }
`;

export const Pagination = styled(MUIPagination)`
  margin-top: ${theme.spacing.xxxlarge}px;

  .MuiPagination-ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-top: ${theme.spacing.xxlarge}px;
    .MuiPagination-ul {
      justify-content: center;
    }
  }
`;

export const SelectedPagination = styled(MUIPaginationItem)`
  background-color: ${theme.colors.primary} !important;
  color: ${theme.colors.white} !important;
`;
