"use client";
import * as Styled from "./styles";
import { Dropdown } from "@gympass/yoga";

const BodyStaticSelectorContactByPersona: React.FC<
  BodyStaticContactByPersonaWidget["payload"] & {
    behavior: BodyStaticContactByPersonaWidget["behaviour"];
  }
> = (props) => {
  if (props.behavior === "HIDDEN") return <></>;
  // TODO: use this function to test locally

  const getFormId = (formUrl: string) => {
    const newUrl = new URL(`${window.location.origin}${location.pathname}`);

    if (formUrl) {
      const formId = new URL(formUrl).searchParams.get("form-id");
      if (formId) {
        newUrl.searchParams.set("form-id", formId);
      }
    }

    return newUrl;
  };

  //TODO: do the cypress unit tests
  return (
    <Styled.Container
      data-cy="static-selector-contact-by-persona"
      id="static-selector-contact-by-persona"
    >
      <Styled.Title className="title">{props.title}</Styled.Title>
      <Styled.PersonaSubtitle>{props.subtitle}</Styled.PersonaSubtitle>
      <Styled.DropdownContent>
        <Dropdown
          //TODO: insert placeholder in dropdown label
          full
          options={props.options.map((option, index: number) => ({
            label: option.title,
            value: option.url,
            selected: props.selectedIndex === index,
          }))}
          onChange={(e: { value: string }) => window.location.replace(e.value)}
        />
      </Styled.DropdownContent>
    </Styled.Container>
  );
};

export default BodyStaticSelectorContactByPersona;
