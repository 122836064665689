import styled from "styled-components";
import { theme } from "@gympass/yoga";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
  TICKET_FORM_MAX_WIDTH_PX,
} from "@/app/_constants/media-query";
import { Box, Text } from "@gympass/yoga";
import { Icon } from "@gympass/yoga";
import { Button } from "@gympass/yoga";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: ${theme.spacing.xxxsmall}px auto 0;

  button:disabled {
    svg {
      fill: transparent;
    }
  }

  fieldset:disabled {
    background-color: #f5f5f5;
    border-color: #bfbfbf;
    -webkit-text-fill-color: #737371;

    svg {
      fill: transparent;
    }

    > input,
    textarea {
      -webkit-text-fill-color: #737371;
    }
  }
`;

export const Wrapper = styled(Box)`
  max-width: ${TICKET_FORM_MAX_WIDTH_PX};

  .persona-title {
    max-width: ${DESKTOP_MAX_WIDTH_REM};
    margin-bottom: ${theme.spacing.xlarge}px;

    @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
      font-size: ${theme.fontSizes.xlarge}px;
      line-height: ${theme.lineHeights.xlarge}px;
      margin: 1rem 0 2rem;
    }
  }
`;

export const DropdownContent = styled(Box)`
  margin-bottom: ${theme.spacing.xxlarge}px;
`;

export const PersonaTitle = styled(Text.Display1)``;

export const SubmitSubtitle = styled(Text.H4)`
  margin-bottom: ${theme.spacing.small}px;
`;

export const BannerText = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BannerIcon = styled(Icon)`
  width: 24px;
  color: ${theme.colors.staminaNew};
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing.small}px;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`;

export const BannerBox = styled(Box)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonLink = styled(Button.Link)`
  padding-left: 2rem;
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    height: fit-content;
    margin-top: ${theme.spacing.medium}px;
    padding: 0;
  }
`;
