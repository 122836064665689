import styled, { css } from "styled-components";
import { theme } from "@gympass/yoga";
import {
  DESKTOP_MAX_WIDTH_REM,
  MOBILE_MAX_WIDTH_REM,
} from "@/app/_constants/media-query";

export const widgetWrapper = css`
  width: 100%;
  padding: 0 ${theme.spacing.huge}px;
  margin: ${theme.spacing.xxxlarge}px auto 0 auto;
  max-width: ${DESKTOP_MAX_WIDTH_REM};

  @media (min-width: ${DESKTOP_MAX_WIDTH_REM}) {
    padding: ${theme.spacing.small}px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin: ${theme.spacing.xxlarge}px 0 0;
    padding: 0 ${theme.spacing.medium}px;
  }
`;

export const Container = styled.section`
  ${widgetWrapper}
`;
