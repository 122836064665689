import styled from "styled-components";
import { Box, theme } from "@gympass/yoga";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import Link from "next/link";
import { widgetWrapper } from "@/app/_components/Section/styles";

export const Container = styled(Box)`
  ${widgetWrapper};
  margin-top: ${theme.spacing.large}px;
`;

export const Breadcrumb = styled(MUIBreadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin: 0 ${theme.spacing.xxxsmall}px;
  }

  .breadcrumb-button {
    padding: 0;
    height: fit-content;
  }
`;

export const BreadcrumbLink = styled(Link)`
  text-decoration: none;
`;
