import { DynamicSessionContentProps } from "../";
import { Accordion, Box } from "@gympass/yoga";
import * as Styled from "./styles";
import { ArticleList } from "@/app/_components/ArticleList";

export const MobileDynamicSessionContent: React.FC<
  DynamicSessionContentProps
> = (props) => {
  return (
    <Styled.SessionContent
      d="flex"
      marginTop="xxlarge"
      data-cy="mobile-dynamic-session-content"
      className="mobile-dynamic-session-content"
    >
      <>
        <hr />
        {props.activeSession?.children?.map((child, childIndex) => (
          <Accordion title={child.title} key={childIndex}>
            <Accordion.Content>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap="small"
              >
                <ArticleList
                  articles={child.articles}
                  eventTracker={{
                    type: "track",
                    event: "hc_all_article",
                  }}
                />
              </Box>
            </Accordion.Content>
          </Accordion>
        ))}
      </>
    </Styled.SessionContent>
  );
};
