import styled from "styled-components";
import { Box, theme, Button } from "@gympass/yoga";

export const VoteBox = styled(Box)`
  margin-top: 40px;
  font-size: 16px;
  display: flex;
  border-top: 1px solid ${theme.colors.light};
  border-bottom: 1px solid ${theme.colors.light};
  padding: 40px 20px;
  gap: 8px;

  .vote-button {
    padding: 0;
    height: 100%;
  }
`;

export const VoteButton = styled(Button.Text).attrs({ secondary: true })``;
