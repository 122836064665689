import styled from "styled-components";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import { Text, theme } from "@gympass/yoga";
import { Section } from "@/app/_components/Section";

export const Container = styled(Section)`
  margin: ${theme.spacing.xxxsmall}px auto 0 auto;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    margin: ${theme.spacing.large}px 0 0;
  }
`;

export const Title = styled(Text.Display1)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    font-size: ${theme.fontSizes.xlarge}px;
    line-height: ${theme.lineHeights.xlarge}px;
  }
`;

export const Subtitle = styled(Text.H5)`
  margin-top: ${theme.spacing.large}px;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    line-height: ${theme.lineHeights.medium}px;
    font-size: ${theme.fontSizes.medium}px;
  }
`;
