"use client";

import { Dropdown } from "@gympass/yoga";
import { DynamicSessionTabsProps } from "..";

export const MobileDynamicSessionTabs: React.FC<DynamicSessionTabsProps> = (
  props
) => {
  return (
    <Dropdown
      full
      data-cy="mobile-dynamic-list-session-tabs"
      className="mobile-dynamic-list-session-tabs"
      options={props.sessions.map((session) => ({
        label: session.title,
        value: session.title,
        selected: props.activeSession.title === session.title,
      }))}
      onChange={(e: DropdownOnChange) =>
        props.updateActiveSession(
          props.sessions.filter((session) => session.title === e.value)[0]
        )
      }
      {...props}
    />
  );
};
