"use client";
import * as Styled from "./styles";
import { WithArticlesDynamicPaginatedLinkList } from "./WithArticlesDynamicPaginatedLinkList";
import { WithNoArticlesDynamicPaginatedLinkList } from "./WithNoArticlesDynamicPaginatedLinkList";

const BodyDynamicPaginatedLinkList: React.FC<
  BodyDynamicPaginatedLinkListWidget["payload"] & {
    behaviour: BodyDynamicPaginatedLinkListBehaviour;
  }
> = (props) => {
  const DEFAULT_PAGINATED_LINK_LIST = (
    <WithNoArticlesDynamicPaginatedLinkList
      fallbackImageName={props.fallbackImageName}
    />
  );
  const ARTICLES_MAP: Record<
    BodyDynamicPaginatedLinkListBehaviour,
    React.ReactNode
  > = {
    WITH_ARTICLES: (
      <WithArticlesDynamicPaginatedLinkList
        metadata={props.metadata}
        articles={props.articles}
        totalPages={props.totalPages}
      />
    ),
    WITH_NO_ARTICLES: DEFAULT_PAGINATED_LINK_LIST,
  };

  return (
    <Styled.Container>
      <Styled.Wrapper>
        {ARTICLES_MAP[props.behaviour] ?? DEFAULT_PAGINATED_LINK_LIST}
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default BodyDynamicPaginatedLinkList;
