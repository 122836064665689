import {
  FlagArgentina,
  FlagBrazil,
  FlagChile,
  FlagGermany,
  FlagIreland,
  FlagItaly,
  FlagMexico,
  FlagSpain,
  FlagUK,
  FlagUS,
} from "@gympass/yoga-icons";

export const DEFAULT_COUNTRY = {
  id: "us",
  name: "United States (EN-US)",
  icon: FlagUS,
  language: "en",
};

export const SUPPORTED_COUNTRIES = [
  {
    id: "ar",
    name: "Argentina (ES-AR)",
    icon: FlagArgentina,
    language: "es",
  },
  {
    id: "br",
    name: "Brasil (PT-BR)",
    icon: FlagBrazil,
    language: "pt",
  },
  {
    id: "cl",
    name: "Chile (ES-CL)",
    icon: FlagChile,
    language: "es",
  },
  {
    id: "de",
    name: "Deutschland (DE-DE)",
    icon: FlagGermany,
    language: "de",
  },
  {
    id: "es",
    name: "España (ES-ES)",
    icon: FlagSpain,
    language: "es",
  },
  {
    id: "ie",
    name: "Ireland (EN-IE)",
    icon: FlagIreland,
    language: "en",
  },
  {
    id: "it",
    name: "Italia (IT-IT)",
    icon: FlagItaly,
    language: "it",
  },
  {
    id: "mx",
    name: "Mexico (ES-MX)",
    icon: FlagMexico,
    language: "es",
  },
  DEFAULT_COUNTRY,
  {
    id: "gb",
    name: "United Kingdom (EN-GB)",
    icon: FlagUK,
    language: "en",
  },
];
