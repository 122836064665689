const HelpCenterLogo = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1" width="30" height="30" rx="6" stroke="#231B22" stroke-width="2" />
            <path d="M21.6625 12.9235L24.9295 11.174C23.8199 8.42902 21.0152 6.8002 17.286 6.8002C11.1218 6.8002 7.30005 10.5405 7.30005 15.97C7.30005 21.9123 12.3546 25.2002 17.1627 25.2002C21.57 25.2002 24.4672 23.1792 25.7 21.49V15.0953H16.2073V18.0513H22.1556L22.1248 20.0724C22.1248 20.0724 20.368 21.7012 17.2244 21.7012C13.8341 21.7012 11.8923 19.4389 11.8923 15.8796C11.8923 12.29 13.8341 10.0278 17.1935 10.0278C19.166 10.0278 20.6454 11.2042 21.6625 12.9235Z" fill="#231B22" />
        </svg>

    )
};

export default HelpCenterLogo;