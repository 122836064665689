"use client";

import * as Styled from "./styles";
import SearchBar from "@/app/_components/SearchBar";

const BodyDynamicSimpleSearch: React.FC<
  BodyDynamicSimpleSearchWidget["payload"]
> = (props) => {
  return (
    <Styled.Wrapper data-cy="simple-search">
      <Styled.Title data-cy="simple-search__title">{props.title}</Styled.Title>
      <Styled.Subtitle data-cy="simple-search__subtitle">
        {props.subtitle}
      </Styled.Subtitle>
      <SearchBar
        placeholder={props.placeholder}
        description={props.description}
        buttonTitle={props.button.title}
      />
    </Styled.Wrapper>
  );
};

export default BodyDynamicSimpleSearch;
