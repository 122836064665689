import { useRef, useCallback, useEffect } from "react";

/**
 * Custom hook that returns a debounced version of the provided callback function.
 * The debounced function will delay the execution of the callback until after
 * the specified delay has elapsed since the last time the debounced function was invoked.
 *
 * @template T - The type of the callback function.
 * @param {T} callback - The callback function to debounce.
 * @param {number} delay - The delay in milliseconds to wait before invoking the callback.
 * @returns {T} - The debounced version of the callback function.
 *
 * @example
 * const debouncedSearch = useDebounce((query) => {
 *   // Perform search
 * }, 500);
 *
 * useEffect(() => {
 *   debouncedSearch(searchQuery);
 * }, [searchQuery, debouncedSearch]);
 */
export const useDebounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  delay: number
): T => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return debouncedFunction as T;
};
