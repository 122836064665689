import { ChangeEvent, useCallback } from "react";
import { Dropdown } from "@gympass/yoga";
import { InputEmail } from "./InputEmail";
import { InputText, InputTextWithArticleSuggestion } from "./InputText";
import { Textarea } from "./TextArea";
import { FileSelector } from "./FileSelector";

type CommonProps = {
  behaviors: string[];
  full: boolean;
  label: string;
  name: string;
  value: string;
  error: string | undefined;
  disabled: boolean;
  onChange: (
    e: ChangeEvent<HTMLInputElement> & {
      value: string;
    }
  ) => void;
  onClean: (cleaned: string) => void;
};

type BuildFormComponentsParams = {
  fieldType: string;
  commonProps: CommonProps;
  onBlur: (value: string) => void;
  handleUploadFiles: (value: string) => void;
  handleChangeDropdown: (e: { value: string }) => void;
  handleBlur: (value: string) => void;
  formId: string;
  options?: Option[];
};

const buildFormComponents = ({
  fieldType,
  commonProps,
  onBlur,
  handleUploadFiles,
  handleChangeDropdown,
  formId,
  options,
}: BuildFormComponentsParams) => {
  const inputProps = { ...commonProps, onBlur };
  const dropdownProps = { ...commonProps, onChange: handleChangeDropdown };

  switch (fieldType) {
    case "email":
      return <InputEmail {...inputProps} />;

    case "text": {
      if (commonProps?.behaviors?.includes("article-suggestion")) {
        return <InputTextWithArticleSuggestion {...inputProps} />;
      }
      return <InputText {...inputProps} />;
    }

    case "textarea":
      return <Textarea {...inputProps} />;

    case "selector":
      return options ? (
        <Dropdown
          {...dropdownProps}
          options={options?.map((option) => ({
            label: option.title,
            value: option.value,
            selected: option.isSelected,
          }))}
        />
      ) : (
        <></>
      );
    case "attachment":
      return (
        <FileSelector
          {...commonProps}
          formId={formId}
          handleUploadFiles={handleUploadFiles}
        />
      );
    default:
      return <></>;
  }
};

export const FormField: React.FC<{
  field: FormFieldType;
  onChange: (field: FormFieldType, value: string) => void;
  formId: string;
  error?: string;
  clearError: (field: FormFieldType) => void;
}> = (props) => {
  const { error, onChange, field, formId } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      return onChange(field, e.target.value);
    },
    [field, onChange]
  );

  const handleChangeDropdown = useCallback(
    (e: { value: string }) => {
      const options = field.options?.reduce(
        (initialOptions: Option[], option: Option) => {
          initialOptions.push({
            ...option,
            isSelected: option.value === e.value,
          });
          return initialOptions;
        },
        []
      );

      return onChange({ ...field, options }, e.value || "");
    },
    [field, onChange]
  );
  const handleUploadFiles = useCallback(
    (value: string) => {
      const newValue = { ...field };
      newValue.value = newValue.value ? `${newValue.value},${value}` : value;

      return onChange(newValue, value || "");
    },
    [field, onChange]
  );

  const handleBlur = useCallback(
    (value: string) => {
      return onChange(field, value || "");
    },
    [field, onChange]
  );

  const handleClean = useCallback(
    (cleaned: string) => {
      return onChange(field, cleaned);
    },
    [field, onChange]
  );

  const commonProps: CommonProps = {
    behaviors: field.behaviors,
    full: true,
    label: field.title,
    name: field.name,
    value: field.value,
    error,
    disabled: field.disabled,
    onChange: handleChange,
    onClean: handleClean,
  };

  return (
    <>
      {buildFormComponents({
        fieldType: field.type,
        commonProps,
        onBlur: handleBlur,
        handleChangeDropdown,
        handleUploadFiles,
        formId,
        options: field.options,
      } as BuildFormComponentsParams)}
    </>
  );
};
