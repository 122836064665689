import React, { ComponentType, SVGProps } from "react";
import dynamic from "next/dynamic";

const IMAGE_SIZES = {
  small: 248,
  medium: 312,
  large: 376,
};

type Size = keyof typeof IMAGE_SIZES;

type SVGWebComponent = ComponentType<SVGProps<SVGSVGElement> & { alt: string }>;

const IMAGES_MAP: Record<Illustrations, SVGWebComponent> = {
  Img2d05: dynamic(
    () =>
      import("@gympass/yoga-illustrations").then((module) => module.Img2d05),
    { ssr: true }
  ),
  Img2d11: dynamic(
    () =>
      import("@gympass/yoga-illustrations").then((module) => module.Img2d11),
    { ssr: true }
  ),
  Activities: dynamic(
    () =>
      import("@yoga-inner/illustrations").then((module) => module.Activities),
    { ssr: true }
  ),
  AllInOne: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.AllInOne),
    { ssr: true }
  ),
  Bike: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Bike),
    { ssr: true }
  ),
  Community: dynamic(
    () =>
      import("@yoga-inner/illustrations").then((module) => module.Community),
    { ssr: true }
  ),
  Dance: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Dance),
    { ssr: true }
  ),
  FinishLine: dynamic(
    () =>
      import("@yoga-inner/illustrations").then((module) => module.FinishLine),
    { ssr: true }
  ),
  Girl: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Girl),
    { ssr: true }
  ),
  HiFive: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.HiFive),
    { ssr: true }
  ),
  Hiking: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Hiking),
    { ssr: true }
  ),
  Shoe: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Shoe),
    { ssr: true }
  ),
  Universe: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Universe),
    { ssr: true }
  ),
  Welcome: dynamic(
    () => import("@yoga-inner/illustrations").then((module) => module.Welcome),
    { ssr: true }
  ),
};

const ImageComponent = ({
  Component,
  size,
  alt,
}: {
  Component: SVGWebComponent;
  size: Size;
  alt: string;
}) => {
  return (
    <Component width={IMAGE_SIZES[size]} height={IMAGE_SIZES[size]} alt={alt} />
  );
};

export const YogaImage: React.FC<{
  image: Illustrations;
  size: Size;
  alt?: string;
}> = ({ image, size, alt = "" }): JSX.Element => (
  <ImageComponent Component={IMAGES_MAP[image]} size={size} alt={alt} />
);
