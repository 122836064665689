"use client";
import * as Styled from "./styles";
import { Button } from "@gympass/yoga";
import { Box, Text } from "@gympass/yoga";
import { useDispatchEvent } from "@/app/lib/segment";
import { login } from "@/app/_services/keycloak";
import { YogaImage } from "@/app/_components/YogaImage";

const BodyStaticHighFullSimpleBannerWithImage: React.FC<
  BodyStaticHighFullSimpleBannerWithImage["payload"] & {
    behaviour: BodyStaticHighFullSimpleBannerWithImageBehaviour;
  }
> = (props) => {
  const handleLogin = () => login();
  const { dispatchAnalycts } = useDispatchEvent();

  if (props.behaviour === "HIDDEN") return null;

  return (
    <Styled.Container data-cy="body-static-high-full-simple-banner-with-image">
      <Box d="flex" gap="large">
        <Styled.Wrapper>
          <Styled.BannerTitle data-cy="high-full-simple-banner-title">
            {props.title}
          </Styled.BannerTitle>
          <Text.Body1
            marginTop="xxsmall"
            lineHeight="medium"
            data-cy="high-full-simple-banner-subtitle"
          >
            {props.subtitle}
          </Text.Body1>
          <Box d="flex" gap="xxsmall" marginTop="large">
            <Button
              secondary
              onClick={() => {
                dispatchAnalycts({
                  type: "track",
                  event: "hc_body_static_high_full_simple_banner_button",
                  properties: {
                    bannerTitle: props.title,
                    bannerSubtitle: props.subtitle,
                    destinyUrl: props.buttons[0]?.url,
                    buttonLabel: props.buttons[0]?.title,
                  },
                });
                handleLogin();
              }}
              data-cy="high-full-simple-banner-first-button"
            >
              {props.buttons[0].title}
            </Button>
            <Button.Outline
              secondary
              onClick={() => {
                dispatchAnalycts({
                  type: "track",
                  event: "hc_body_static_high_full_simple_banner_button",
                  properties: {
                    bannerTitle: props.title,
                    bannerSubtitle: props.subtitle,
                    destinyUrl: props.buttons[1]?.url,
                    buttonLabel: props.buttons[1]?.title,
                  },
                });
              }}
              data-cy="high-full-simple-banner-second-button"
            >
              <Styled.LinkButton
                prefetch={false}
                href={`${props.buttons[1]?.url}`}
              >
                {props.buttons[1].title}
              </Styled.LinkButton>
            </Button.Outline>
          </Box>
        </Styled.Wrapper>
        <Styled.ImageWrapper>
          <YogaImage
            image={props.image.icon}
            alt={props.image.alt}
            size="medium"
          />
        </Styled.ImageWrapper>
      </Box>
    </Styled.Container>
  );
};

export default BodyStaticHighFullSimpleBannerWithImage;
