"use client";
import { Box } from "@gympass/yoga";
import * as Styled from "./styles";
import { useParams } from "next/navigation";
import { TrackEventPayload, useDispatchEvent } from "@/app/lib/segment";
import { dispatchInbentaEvent } from "@/app/_server-actions/redirect-from-search-results-to-article-page";

export const ArticleList: React.FC<{
  articles: Article[];
  title?: string;
  eventTracker: MakeOptional<TrackEventPayload, "properties">;
}> = (props) => {
  const params = useParams<{ region: string; readableId?: string }>();
  const { dispatchAnalycts } = useDispatchEvent();

  const dispatchNavigateToArticleEvent = ({
    articleTitle,
    articleId,
    inbentaClickCode,
  }: {
    articleTitle: string;
    articleId: string;
    inbentaClickCode?: string;
  }) => {
    dispatchAnalycts({
      ...props.eventTracker,
      properties: {
        ...(props.eventTracker.properties || {}),
        article_title: articleTitle,
      },
    });
    if (inbentaClickCode) {
      dispatchInbentaEvent({
        region: params.region,
        articleId,
        inbentaClickCode,
      });
    }
  };

  return (
    <Box data-cy="article-list">
      {props.title && (
        <Styled.Title data-cy="article-list-title">{props.title}</Styled.Title>
      )}
      <Styled.Ul data-cy="articles-container">
        {props.articles?.map((article, index) => (
          <li key={index} data-cy="article-li">
            <Styled.ArticleLink
              prefetch={false}
              href={`/${params.region}/article/${article.readable_id}`}
              onClick={() =>
                dispatchNavigateToArticleEvent({
                  articleTitle: article.title,
                  articleId: article.id,
                  inbentaClickCode: article.metadata?.inbenta_click_code,
                })
              }
              title={article.title}
              data-cy="article-button"
              tabIndex={0}
            >
              <Styled.LinkText data-cy="article-text">
                {article.title}
              </Styled.LinkText>
            </Styled.ArticleLink>
          </li>
        ))}
      </Styled.Ul>
    </Box>
  );
};
