import styled from "styled-components";
import { Box, Text, theme } from "@gympass/yoga";

export const TabsWrapper = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.light};
`;

export const ButtonWrapper = styled.div<ActiveSessionProps>`
  border-bottom: 2px solid
    ${({ $isActive }) => ($isActive ? theme.colors.primary : "transparent")};
`;

export const ButtonTab = styled.button<ActiveSessionProps>`
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.xlarge}px;
  cursor: pointer;
`;

export const TextTab = styled(Text.H3)`
  text-transform: uppercase;
  letter-spacing: 1px;
`;
