"use client";
import { useMemo, useState } from "react";
import * as Styled from "./styles";
import { ArticleList } from "@/app/_components/ArticleList";
import { Pagination } from "../../../_components/Pagination";
import { ARTICLES_PER_PAGE, INITIAL_PAGE } from "@/app/_constants/articles";

export const WithArticlesDynamicPaginatedLinkList: React.FC<
  Partial<PaginatedArticlesApiResponse & { totalPages: number }>
> = (props) => {
  const [page, setPage] = useState<number>(INITIAL_PAGE);

  const getPaginatedArticles = (
    articles: Article[],
    page: number
  ): Article[] => {
    const startIndex = (page - 1) * ARTICLES_PER_PAGE;
    const endIndex = page * ARTICLES_PER_PAGE;
    return articles.slice(startIndex, endIndex);
  };

  const articlesPerPage: Article[] = useMemo(
    () => (props?.articles ? getPaginatedArticles(props?.articles, page) : []),
    [props?.articles, page]
  );

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <>
      <Styled.ArticleListWrapper>
        <ArticleList
          articles={articlesPerPage}
          eventTracker={{
            type: "track",
            event: "hc_search_results",
          }}
        />
      </Styled.ArticleListWrapper>

      <Pagination
        page={page}
        onChange={handleChangePage}
        totalPages={props?.totalPages || 0}
      />
    </>
  );
};
