"use client";
import Svg from "@/app/_widgets/svg";
import * as Styled from "@/app/_widgets/footer-static-default-footer/styles";
import { Linkedin, Instagram, Youtube } from "@gympass/yoga-icons";
import { useDispatchEvent } from "@/app/lib/segment";
import CookiesSettingsLink from "./CookiesSettingsLink";

const LOGO_MAP: Record<Logo, React.ReactNode> = {
  Gympass: <Svg.GympassLogo />,
  Wellhub: <Svg.WellhubWhiteLogo />,
};

const FooterStaticDefaultFooterComponent: React.FC<
  FooterStaticDefaultFooter["payload"]
> = (props) => {
  const { dispatchAnalycts } = useDispatchEvent();

  const dispatchSocialMediaClickEvent = (name: string, url: string) => {
    dispatchAnalycts({
      type: "track",
      event: "hc_click_social_media",
      properties: {
        name,
        destinyUrl: url,
      },
    });
  };

  const dispatchFooterLinkClickEvent = (name: string, url: string) => {
    dispatchAnalycts({
      type: "track",
      event: "hc_click_footer_link",
      properties: {
        name,
        destinyUrl: url,
      },
    });
  };

  const dispatchStoreClickEvent = (name: string, url: string) => {
    dispatchAnalycts({
      type: "track",
      event: "hc_click_app_link",
      properties: {
        name,
        destinyUrl: url,
      },
    });
  };

  return (
    <Styled.Footer data-cy="footer">
      <Styled.Wrapper>
        <Styled.FooterWrapper flexDirection="column">
          <Styled.FooterLogoWrapper>
            {LOGO_MAP[props.logoImage] ?? <Svg.GympassLogo />}
            <Styled.FooterLogoText>{props.copyright}</Styled.FooterLogoText>
          </Styled.FooterLogoWrapper>
          <Styled.FooterMenu
            d="flex"
            justifyContent="space-between"
            gap="xlarge"
          >
            <Styled.FooterLinks>
              {props.menu.map((link, index) => (
                <Styled.FooterLink
                  href={link.url}
                  key={index}
                  data-cy="footer-link"
                  onClick={() =>
                    dispatchFooterLinkClickEvent(link.title, link.url)
                  }
                  title={link.title}
                >
                  <Styled.LinkText>{link.title}</Styled.LinkText>
                </Styled.FooterLink>
              ))}
              <CookiesSettingsLink />
            </Styled.FooterLinks>
            <Styled.FooterSocialMedias d="flex" gap="medium">
              {props.social.linkedin && (
                <Styled.FooterLink
                  href={props.social.linkedin}
                  data-cy="footer-social-linkedin"
                  aria-label="Linkedin"
                  onClick={() =>
                    dispatchSocialMediaClickEvent(
                      "Linkedin",
                      props.social.linkedin
                    )
                  }
                >
                  <Linkedin fill="white" width={20} height={20} />
                </Styled.FooterLink>
              )}
              {props.social.instagram && (
                <Styled.FooterLink
                  href={props.social.instagram}
                  data-cy="footer-social-instagram"
                  aria-label="Instagram"
                  onClick={() =>
                    dispatchSocialMediaClickEvent(
                      "Instagram",
                      props.social.instagram
                    )
                  }
                >
                  <Instagram fill="white" width={20} height={20} />
                </Styled.FooterLink>
              )}
              {props.social.youtube && (
                <Styled.FooterLink
                  href={props.social.youtube}
                  data-cy="footer-social-youtube"
                  aria-label="Youtube"
                  onClick={() =>
                    dispatchSocialMediaClickEvent(
                      "Youtube",
                      props.social.youtube
                    )
                  }
                >
                  <Youtube fill="white" width={20} height={20} />
                </Styled.FooterLink>
              )}
            </Styled.FooterSocialMedias>
          </Styled.FooterMenu>
          <Styled.FooterStores d="flex" gap="medium">
            {props.store.googlePlay && (
              <Styled.FooterLink
                href={props.store.googlePlay}
                data-cy="footer-store-google-play"
                aria-label="GooglePlay"
                onClick={() =>
                  dispatchStoreClickEvent("Google Play", props.store.googlePlay)
                }
              >
                <Svg.GooglePlay />
              </Styled.FooterLink>
            )}
            {props.store.appstore && (
              <Styled.FooterLink
                href={props.store.appstore}
                data-cy="footer-store-appstore"
                aria-label="AppStore"
                onClick={() =>
                  dispatchStoreClickEvent("Google Play", props.store.appstore)
                }
              >
                <Svg.AppStore />
              </Styled.FooterLink>
            )}
          </Styled.FooterStores>
        </Styled.FooterWrapper>
      </Styled.Wrapper>
    </Styled.Footer>
  );
};

export default FooterStaticDefaultFooterComponent;
