import styled, { css } from "styled-components";
import { theme, Button, Menu, Avatar, Text, Icon, Box } from "@gympass/yoga";
import { MOBILE_MAX_WIDTH_REM } from "@/app/_constants/media-query";
import Link from "next/link";

export const Header = styled.header`
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    padding: 1rem 1.25rem;
  }
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.xsmall}px;
`;

export const FlagButtonLink = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const LoggedButtonContainer = styled.div`
  .logged-user-button {
    padding: 0;
  }
`;

export const UnloggedButtonContainer = styled.div`
  .unlogged-user-button {
    padding: 8px;
  }

  .unlogged-user-button > svg {
    margin-right: 0;
  }
`;

export const FlagButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
`;

export const FlagMenu = styled(Menu.List)`
  width: 16px;
  height: 16px;
`;

export const MenuItem = styled(Menu.Item)`
  width: 200px;
  overflow: hidden;
  ${({ active }) =>
    active &&
    css`
      svg {
        margin-right: ${theme.spacing.small}px;
      }
      color: ${theme.colors.primary};
    `}
`;

// This component has an !important on width, height and bgColor because yoga does not allow us to override this styles
export const AvatarCircle = styled(Avatar.Circle)`
  background-color: #f5f5fa !important;
  border: none;
  width: 32px !important;
  height: 32px !important;
  box-shadow: none;
  border: 1px solid #d1d1d1 !important;
`;

export const UserButton = styled(Button)`
  background-color: unset;
  margin: unset;
  padding: unset;
  gap: ${theme.spacing.xxxsmall}px;
`;

export const UserName = styled(Text.Body1).attrs({ fontWeight: "semiBold" })`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    display: none;
  }
`;

export const ArrowDownIcon = styled(Icon)`
  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    display: none;
  }
`;

export const LogoutBox = styled(Box)`
  background-color: ${theme.colors.WHITE};
  box-shadow: 0px 2px 6px 0px rgba(152, 152, 166, 0.25);
  border-radius: 8px;
  display: inline-flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

export const LogoutMenuItem = styled(Menu.Item)`
  width: 144px;
`;

export const SearchMenuItem = styled(Box)`
  display: inline-flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Foundation-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(152, 152, 166, 0.25);
  gap: 10px;

  input {
    width: 428px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH_REM}) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: 100vw;
    width: 100vw;

    form {
      width: calc(100% - 32px);
    }

    input {
      width: 100%;
    }
  }
`;
